import React, { useState, useEffect, useCallback, useRef } from 'react';
import './StyleAperçu.css'


import disque from "./assets/images/disque.png";
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { linkHome } from '../../../../axios-client';
import WebFont from 'webfontloader';
import LyricsDisplay from '../../../../slidesShow/components/newAnimations/Lyrics';
import { DateTime } from 'luxon';

// const slideImages = [
//     {
//         url: image1,
//         caption: 'Slide 1',
//         imageEffect: 'zoom-in-in',
//         styleTitle: 'ombre',
//         artiste: 'Artiste 1',
//         inverse: false
//     },
//     {
//         url: image2,
//         caption: 'Slide 2',
//         imageEffect: 'zoom-out',
//         styleTitle: 'elévation',
//         artiste: 'Artiste 2',
//         inverse: false
//     },
//     {
//         url: image3,
//         caption: 'Slide 3',
//         imageEffect: 'monter',
//         styleTitle: 'contour',
//         artiste: 'Artiste 3',
//         inverse: true
//     },
//     {
//         url: image4,
//         caption: 'Slide 4',
//         imageEffect: 'descendre',
//         styleTitle: 'ombre',
//         artiste: 'Artiste 4',
//         inverse: false
//     },
//     {
//         url: image5,
//         caption: 'Slide 5',
//         imageEffect: 'droite',
//         styleTitle: 'elévation',
//         artiste: 'Artiste 5',
//         inverse: false
//     },
//     {
//         url: image6,
//         caption: 'Slide 6',
//         imageEffect: 'gauche',
//         styleTitle: 'contour',
//         artiste: 'Artiste 5',
//         inverse: false
//     },
// ];

function PreviewSlide({
    idRadio,
    configurationRadio,
    durationTransition,
    dataTransitionEffect,
    dataSlideShowEffect,
}) {


    const [currentSlide, setCurrentSlide] = useState(0);

    const [previousSlide, setPreviousSlide] = useState(0);
    const [currentTransitionEffect, setCurrentTransitionEffect] = useState('');
    const [currentSlideShowEffect, setCurrentSlideShowEffect] = useState('');

    const [slideImages, setSlideImages] = useState([])
    const [logoRadio, setLogoRadio] = useState()
    // const [configurationRadio, setConfigurationRadio] = useState()
    const [lengthSlideImages, setLengthSlideImages] = useState(5)
    // const [durationTransition, setDurationTransition] = useState(5)
    // const [dataTransitionEffect, setDataTransitionEffect] = useState([])
    // const [dataSlideShowEffect, setDataSlideShowEffect] = useState([])
    const [etatLoading, setEtatLoading] = useState(true)
    const [slideImagesTime, setSlideImagesTime] = useState([])
    const [lastTitle, setLastTitle] = useState('')
    const [lastArtiste, setLastArtiste] = useState('')
    const [lastAlbumCover, setLastAlbumCover] = useState('')
    const [streamUrl, setStreamUrl] = useState('')
    const [titre, setTitre] = useState('')

    const [loading, setLoading] = useState(true)
    const [end, setEnd] = useState(true);
    // const [updateStartTime, setUpdateStartTime] = useState(true);
    // const [imageLoaded, setImageLoaded] = useState([]);

    const [Lyrics, setLyrics] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [nextTitleStartTime, setNextTitleStartTime] = useState(20000);
    const [nextTitle, setNextTitle] = useState({});
    const [musicData, setMusicData] = useState([]);
    const [actualMusic, setActualMusic] = useState("");
    const [actualJingle, setActualJingle] = useState("");

    const [firstTime, setFirstTime] = useState(true)
    const [audioElapsedTime, setAudioElapsedTime] = useState()

    const audioRef = useRef(null);


    const delai = 2000


    const [lyricsAllowed, setLyricsAllowed] = useState(false);

    // function calculateNextFetchTime(nextTime, currentStartTime) {
    //     const formattedAssetStartTime = nextTime.replace(' ', 'T');
    //     const startTime = DateTime.fromISO(formattedAssetStartTime, { zone: 'Europe/Paris' }).toMillis();
    //     const currentTime = DateTime.now().setZone('Europe/Paris').toMillis();

    //     const formattedAssetcurrentAudioStart = currentStartTime.replace(' ', 'T');
    //     const currentAudioStart = DateTime.fromISO(formattedAssetcurrentAudioStart, { zone: 'Europe/Paris' }).toMillis();

    //     let elapsed = startTime - currentTime;

    //     const audioElapsed = currentTime - currentAudioStart;

    //     if (elapsed <= 0) {
    //         // alert("heyyyy")
    //         elapsed = 20000
    //     }
    //     console.log("lancé : ", startTime, currentTime, nextTime, elapsed);
    //     console.log("dans ", (elapsed / 1000) + delai, " secondes");
    //     elapsed += delai
    //     setNextTitleStartTime(elapsed)

    //     setAudioElapsedTime(audioElapsed / 1000)

    //     return elapsed

    // }

    async function updateSlideOnTime(data) {
        console.log("updating slides on time......");
        setLastTitle(data.titre);
        setLastArtiste(data.artist);
        setLastAlbumCover(data.album_cover);
        setFirstTime(false)



        console.log("updating startingTime again...");
        setPreviousSlide(0)
        setCurrentSlide(0)

        setActualMusic(data.music)
        setActualJingle(data.jingle)
        console.log('actual music  updated', data.music);
        const filteredLyrics = data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
        setLyrics(filteredLyrics)

        setStartTime(data.startTime)


        // alert("heyyyyyy")
        // console.log("hey here is my test ..........");
        if (data?.musicImages.length > 0) {
            if (data?.musicImages.length === 1) {
                // concatener avec les liste d'images par defaut
                const array = slideImages.length !== 0 ? data.musicImages.concat(slideImages) : [data.musicImages[0], logoRadio]
                // console.log("array: ", array, slideImages);
                setSlideImagesTime(array);
            } else {
                setSlideImagesTime(data.musicImages);
            }
        } else {
            setSlideImagesTime(slideImages);
        }
    }



    async function musicDataProcessing() {
        try {
            setEnd(false)
            console.log("nextTitleStartTime", nextTitleStartTime);
            const data = (musicData[0].music !== actualMusic && musicData[0].titre !== "") ? musicData[0] : musicData[1] //recuperer la premiere liste de donnée
            console.log("next data", musicData, data);
            await updateSlideOnTime(data)
            const next = parseInt(data.duree * 1000) + delai
            console.log("data.duree: ", next);
            setNextTitleStartTime(next)

            // const response = await fetch(`${linkHome}/getImageByRadio/?radioId=${idRadio}&lastTitle=${lastTitle}`);
            // let donnee = await response.json()
            // // console.log("donnee[0].nextTitleStartTime", donnee[0].nextTitleStartTime);
            // if (donnee[0].nextTitleStartTime !== "") {
            //     setMusicData(donnee)
            //     // calculateNextFetchTime(donnee[0].nextTitleStartTime, donnee[0].startTime, donnee[0].duree)
            // }
            // else {
            //     // mettre afin de ne pas rendre le lyrics à nouveau
            //     setUpdateStartTime(false)
            //     console.log("updating to false");
            //     // ....

            //     setNextTitleStartTime(5000)

            // }
            // console.log("new donnee: ", donnee);
            // refaire la requete 5s apres pour compenser les latence en cas de non actualisation du server client à temps
            // setNextTitleStartTime(5000)


        }
        catch (error) {
            console.log("and error occured", error);
        };
    }


    const fetchDataWithTime = async (default_images) => {
        try {
            const response = await fetch(`${linkHome}/getImageByRadio/?radioId=${idRadio}&lastTitle=${lastTitle}`);
            let data = await response.json()
            setMusicData(data)
            setNextTitle(data[1])
            data = data[0] //recuperer la premiere liste de donnée

            // const rndomMusic = listMusic[Math.floor(Math.random() * listMusic.length)];
            setActualMusic(data.music)
            setActualJingle(data.jingle)

            console.log('actual music ', data.music);

            setEnd(false)

            if (data.titre && data.isDataChanged) {
                setLastTitle(data.titre);
                setLastArtiste(data.artist);
                setLastAlbumCover(data.album_cover);
                console.log("data lyrics ", data.lyrics);
                // setLyrics(data.lyrics)
                const filteredLyrics = data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
                setLyrics(filteredLyrics)
                setStartTime(data.startTime)

                // if (data.nextTitleStartTime !== "")
                //     calculateNextFetchTime(data.nextTitleStartTime, data.startTime, data.duree)
                const next = parseInt(data.duree) * 1000 + delai

                setNextTitleStartTime(next)


                console.log("Durée........: ", next);

                setPreviousSlide(0)
                setCurrentSlide(0)

                if (data?.musicImages.length > 0) {
                    if (data?.musicImages.length === 1) {
                        // concatener avec les liste d'images par defaut
                        const array = default_images.length !== 0 ? data.musicImages.concat(default_images) : [data.musicImages[0], logoRadio]
                        // console.log("array: ", array, default_images);
                        setSlideImagesTime(array);
                    } else {
                        setSlideImagesTime(data.musicImages);
                    }
                } else {
                    // alert('ok')
                    // console.log('IMAGE défauts : ',default_images);
                    setSlideImagesTime(default_images);
                }

                setEtatLoading(false);

            } else if (!data.titre && data.isDataChanged) {
                if (data?.musicImages.length) {
                    setSlideImagesTime(data.musicImages);
                } else {
                    setSlideImagesTime(default_images);
                }

                setLastAlbumCover(data.album_cover);
                setSlideImagesTime(default_images)
                // setLyrics(data.lyrics)
                const filteredLyrics = data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
                setLyrics(filteredLyrics)
                setStartTime(data.startTime)

                setEtatLoading(false);

            }
        }
        catch (error) {
            console.log("and error occured", error);
        };

    };


    const fetchData = async () => {
        try {
            const logoRes = await axios.get(`${linkHome}/radios/${idRadio}/`);
            setLogoRadio(logoRes.data.logo);
            setLastAlbumCover(logoRes.data.logo)
            // props.setStreamUrl(logoRes.data.audioLinkStream);
            setLyricsAllowed(logoRes.data.allowLyrics)
            // console.log("stream link ", logoRes.data.allowLyrics);

            const imagesRes = await axios.get(`${linkHome}/default_images/${idRadio}/`);
            setSlideImages(imagesRes.data);
            setSlideImagesTime(imagesRes.data || []);
            const configRes = await axios.get(`${linkHome}/current_config/?radio=${idRadio}`);
            // setConfigurationRadio(configRes.data);
            // setDataTransitionEffect(configRes.data?.transitionEffect);
            // setDurationTransition(configRes.data?.transitionDuration);
            // setDataSlideShowEffect(configRes.data?.slideShowEffect);
            setCurrentTransitionEffect(configRes.data?.transitionEffect[0]);
            setCurrentSlideShowEffect(configRes.data?.slideShowEffect[0]);

            await fetchDataWithTime(imagesRes.data);

            setLoading(false);


        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };


    console.log("newComponent");

    // useEffect(() => {
    //     if (!loading) {

    //         console.log('lastTitle newwwwww : ', lastTitle);
    //     }
    // }, [lastTitle])

    useEffect(() => {
        fetchData()
    }, [])



    useEffect(() => {
        if (!loading) {
            const intervalEnd = setInterval(async () => {
                setEnd(true)
                // const data = (musicData[0].music !== actualMusic && musicData[0].titre !== "") ? musicData[0] : musicData[1] //recuperer la premiere liste de donnée
                // setActualJingle(musicData[0].jingle)
                console.log("enddd................");
            }, nextTitleStartTime - 1000);

            return () => clearInterval(intervalEnd);
        }
    }, [nextTitleStartTime, musicData]);

    useEffect(() => {
        async function updatingNewData() {
            console.log("updating.......");
            const response = await fetch(`${linkHome}/getImageByRadio/?radioId=${idRadio}&lastTitle=${lastTitle}`);
            let donnee = await response.json()
            // console.log("donnee[0].nextTitleStartTime", donnee[0].nextTitleStartTime);
            if (donnee[0].nextTitleStartTime !== "") {
                setMusicData(donnee)
                setNextTitle(donnee[1])
                console.log("updating sucessfull");

            }
            else {

                console.log("updating to false");

            }
            console.log("updated data: ", donnee);
        }

        if (!loading && idRadio && lastTitle) {
            // const MusicDataSlideTime = setInterval(async () => {
            updatingNewData()
            // }, 120000); //chaque 120s/2mn

            // return () => clearInterval(MusicDataSlideTime
            // );
        }
    }, [loading, idRadio, lastTitle]);

    useEffect(() => {
        if (!loading) {
            const interval = setInterval(async () => {
                await musicDataProcessing();
            }, nextTitleStartTime);

            return () => clearInterval(interval);
        }
    }, [loading, slideImages, logoRadio, lastTitle, nextTitleStartTime]);



    useEffect(() => {
        if (!loading && !etatLoading && slideImagesTime.length !== 0) {
            const slideInterval = setInterval(() => {
                setPreviousSlide(currentSlide)
                const currentSlideEffect = dataSlideShowEffect[Math.floor(Math.random() * dataSlideShowEffect.length)];
                setCurrentSlideShowEffect(currentSlideEffect);
                // console.log("currentSlideEffect", currentSlideEffect);

                const currentTransitionEffect = dataTransitionEffect[Math.floor(Math.random() * dataTransitionEffect.length)];
                // console.log("currentTransitionEffect", currentTransitionEffect);
                setCurrentTransitionEffect(currentTransitionEffect);

                setCurrentSlide((prevSlide) => {
                    const nextSlide = (prevSlide + 1) % slideImagesTime.length;
                    return nextSlide;
                });
            }, durationTransition * 1000);

            return () => clearInterval(slideInterval);
        }
    }, [durationTransition, loading, slideImagesTime, dataSlideShowEffect, currentSlide]);

    // useEffect(() => {
    //     if (!loading && !etatLoading && slideImagesTime.length !== 0) {
    //         const interval = setInterval(() => {
    //             const currentTransitionEffect = dataTransitionEffect[Math.floor(Math.random() * dataTransitionEffect.length)]
    //             setCurrentTransitionEffect(currentTransitionEffect);
    //             console.log(currentTransitionEffect);


    //         }, durationTransition * 1000); // Définissez l'intervalle de temps entre chaque changement d'image (en millisecondes)

    //         return () => clearInterval(interval); // Nettoie l'intervalle lors du démontage du composant


    //     }

    // }, [durationTransition, lengthSlideImages, loading, dataTransitionEffect])

    // catching up the audio time
    // useEffect(() => {
    //     const audio = audioRef.current;
    //     if (audio && firstTime && audioElapsedTime) {
    //         audio.currentTime = audioElapsedTime; // Set the current time to 600 ms (0.6 seconds)
    //         audio?.play();
    //         setFirstTime(false)
    //         console.log("audio catched up", audioElapsedTime);
    //     }
    // }, [firstTime, audioElapsedTime]);

    // const handleImageLoad = useCallback((index) => {
    //     // console.log("image loaded ........");
    //     setImageLoaded((prev) => {
    //         const newImageLoaded = [...prev];
    //         // console.log("image loaded ........");
    //         newImageLoaded[index] = true;
    //         return newImageLoaded;
    //     });
    // }, []);

    useEffect(() => {
        if (configurationRadio?.titlePolice) {
            WebFont.load({
                google: {
                    families: [configurationRadio?.titlePolice]
                }
            });
        }
    }, [configurationRadio?.titlePolice]);

    useEffect(() => {
        if (configurationRadio?.artistPolice) {
            WebFont.load({
                google: {
                    families: [configurationRadio?.artistPolice]
                }
            });
        }
    }, [configurationRadio?.artistPolice]);


    const renderSlides = () => {
        return slideImagesTime.map((slideImage, index = currentSlide) => {
            return (
                <div style={{ position: 'relative' }} className={``} key={index}>
                    <div
                        className={`${currentTransitionEffect} ${currentSlide === index && `${currentTransitionEffect}-checked`}`}
                    >
                        <div style={{
                            backgroundImage: `url(${slideImagesTime[index]?.replace(/\\/g, '/') || slideImages[0]})`,
                            // background: `yellow`,
                            position: "absolute",
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            width: "120%",
                            height: "120%",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            // filter: 'blur(12px)',
                            filter: ` ${configurationRadio?.blackWhiteImage ? 'grayscale(1) blur(12px)' : ' grayscale(0) blur(12px)'}`,

                            zIndex: -3,
                            objectFit: 'cover',
                        }}
                        ></div>

                        <img
                            alt=''
                            className={`image ${currentSlide === index && `image-effect-${currentSlideShowEffect}`}`}
                            // src={slideImagesTime[index]?.replace(/\\/g, '/')}
                            // onError={handleImageError}
                            src={slideImagesTime[index]?.replace(/\\/g, '/') || slideImages[0]}
                            style={{
                                animation: currentSlide === index && ` ${currentSlideShowEffect} ${durationTransition - 2}s forwards`,
                                filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                                objectFit: 'contain',
                                position: 'relative',
                                // display: imageLoaded[index] ? 'block' : 'none',
                                zIndex: 1
                            }}
                        />



                        {/* <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                }}>
                    <h1 style={{fontSize:55,color:'yellow'}}>{currentSlide} -- {previousSlide} ----</h1>
                    <div 
                    style={{
                        backgroundImage: `url(${(slideImagesTime[previousSlide])?.replace(/\\/g, '/')})`,
                        height:100,
                        width:100,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        objectFit:'contain'
                        
                    }}></div>
                </div> */}

                    </div>
                    {(previousSlide === index - 1 || (previousSlide === slideImagesTime.length - 1 && index === 0) || (previousSlide === currentSlide)) &&
                        <div
                            style={{
                                height: '100vh',
                                width: '100%',
                                animation: `${currentTransitionEffect}-out 1s ease-in-out`,
                                zIndex: -1
                            }}
                        // className={`combiner2-checked`}

                        >

                            <div style={{
                                backgroundImage: previousSlide === currentSlide ? `url(${slideImagesTime[previousSlide]?.replace(/\\/g, '/')})` : `url(${slideImagesTime[previousSlide]?.replace(/\\/g, '/')})`,
                                // backgroundImage: `url(${(currentSlide === 0 ? slideImagesTime[currentSlide] : slideImagesTime[previousSlide])?.replace(/\\/g, '/')})`,
                                // background:'yellow',
                                position: "absolute",
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                                width: "120%",
                                height: "120%",
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                // filter: 'blur(12px)',
                                filter: ` ${configurationRadio?.blackWhiteImage ? 'grayscale(1) blur(12px)' : ' grayscale(0) blur(12px)'}`,
                                zIndex: 0,
                                objectFit: 'cover',
                            }}></div>

                            <img
                                alt=''
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                                    objectFit: 'contain',
                                    zIndex: 0
                                }}
                                className={`image`}
                                src={index === 0 ? slideImagesTime[slideImagesTime.length - 1] : slideImagesTime[previousSlide]}
                            // onLoad={() => handleImageLoad(index)}
                            // style={{
                            //     animation: currentSlide === index && ` ${currentSlideShowEffect} ${durationTransition - 2}s forwards`,
                            //     filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                            //     objectFit: 'contain',
                            //     position: 'relative',
                            //     display: imageLoaded[index] ? 'block' : 'none',
                            // }}
                            />

                        </div>}

                </div>
            )
        });
    };

    const hexToRgba = (hex, alpha) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    return (
        <div className="slide-container"
            // style={{
            //     backgroundImage: `url(${logoRadio})`,
            // }}
        >




            {(!loading && !end) && <>

                <div style={{
                    position: 'absolute',
                    zIndex: 3,
                    top: configurationRadio?.walletPosition.includes('top') && '1%',
                    left: configurationRadio?.walletPosition.includes('left') && '1%',
                    bottom: configurationRadio?.walletPosition.includes('bottom') && (Lyrics.length && lyricsAllowed) ? `${configurationRadio?.lyricsFilterSize + 10}%` : '11%',
                    right: configurationRadio?.walletPosition.includes('right') && '1%',
                    width: '25%',
                    height: configurationRadio?.walletH,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20
                }}>

                    <div className='song-details' style={{

                    }}>
                        <div className='song-image' style={{
                            height: "100%",
                            width: configurationRadio?.walletW,
                        }}>
                            <img className='' alt='WALLET' src={lastAlbumCover !== "" ? lastAlbumCover : logoRadio} style={{ height: '100%', width: '100%', borderRadius: 10 }} />
                        </div>
                        <div style={{
                            position: 'relative',
                            width: `50%`,
                            height: '100%',
                        }}>
                            <div className={`song-title-${configurationRadio?.textEffect}`} style={{
                                position: 'absolute',
                                // top: configurationRadio?.titlePosition.includes('top') && 0,
                                left: configurationRadio?.titlePosition.includes('left') && 0,
                                bottom: configurationRadio?.titlePosition.includes('bottom') && 0,
                                right: configurationRadio?.titlePosition.includes('right') && 0,
                                transform: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && 'translateY(-50%)',
                                top: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && '50%',
                            }}>
                                <h1 style={{
                                    color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.titleColor : 'transparent',
                                    fontSize: configurationRadio?.titleSize,
                                    fontFamily: configurationRadio?.titlePolice,
                                    WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.titleColor}`,
                                }}>{configurationRadio?.positionInversion ? lastTitle : lastArtiste}</h1>

                                <span style={{
                                    color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.artistColor : 'transparent',
                                    fontSize: configurationRadio?.artistSize,
                                    fontFamily: configurationRadio?.artistPolice,
                                    WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.artistColor}`,
                                }}>{configurationRadio?.positionInversion ? lastArtiste : lastTitle}</span>
                            </div>
                        </div>

                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <span style={{
                        color: "white",
                        fontSize: 11,
                        fontWeight: 900,
                    }}>Titre suivant</span>

                        <span style={{
                            color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.artistColor : 'transparent',
                            fontSize: configurationRadio?.artistSize,
                            fontFamily: configurationRadio?.artistPolice,
                            WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.artistColor}`,
                        }}>{nextTitle?.artist?.length > 20 ? `${nextTitle?.artist.substring(0, 20)}...` : nextTitle?.artist} - {nextTitle?.titre?.length > 25 ? `${nextTitle?.titre.substring(0, 25)}...` : nextTitle?.titre}</span>
                    </div>

                </div>




                <div className='logo' style={{
                    zIndex: 3,
                    position: 'absolute',
                    top: configurationRadio?.logoPosition.includes('top') && '1%',
                    left: configurationRadio?.logoPosition.includes('left') && '1%',
                    bottom: configurationRadio?.logoPosition.includes('bottom') && (Lyrics.length && lyricsAllowed) ? `${configurationRadio?.lyricsFilterSize + 1}%` : '1%',
                    right: configurationRadio?.logoPosition.includes('right') && '1%',
                    height: configurationRadio?.logoH,
                    width: configurationRadio?.logoW,
                }}>
                    <img
                        alt=''
                        className='logo' src={logoRadio} style={{ height: '100%', width: '100%', borderRadius: 10 }}
                    />
                </div>


                <div style={{
                    position: 'absolute',
                    zIndex: 3,
                    position: 'absolute',
                    right: "1%",
                    bottom: (Lyrics.length && lyricsAllowed) ? `${configurationRadio?.lyricsFilterSize + 2}%` : '1%',
                }}>

                </div>
            </>
            }

            {(!loading && end) &&
                <>
                    <div className='song-details' style={{
                        position: 'absolute',
                        zIndex: 3,
                        top: '1%',
                        left: '1%',
                        width: `100%`,
                        height: configurationRadio?.walletH,
                        display: 'flex',
                        flexDirection: "column"
                    }}>
                        <div className='song-image' style={{
                            height: "100%",
                            width: configurationRadio?.walletW,
                        }}>
                            <img className='' alt='WALLET' src={nextTitle?.album_cover ? nextTitle?.album_cover : logoRadio} style={{ height: '100%', width: '100%', borderRadius: 10 }} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <span style={{
                            color: "white",
                            fontSize: 11,
                            fontWeight: 900,
                        }}>Titre suivant</span>

                            <span style={{
                                color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.artistColor : 'transparent',
                                fontSize: configurationRadio?.artistSize,
                                fontFamily: configurationRadio?.artistPolice,
                                WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.artistColor}`,
                            }}>{nextTitle?.artist?.length > 20 ? `${nextTitle?.artist.substring(0, 20)}...` : nextTitle?.artist} - {nextTitle?.titre?.length > 25 ? `${nextTitle?.titre.substring(0, 25)}...` : nextTitle?.titre}</span>
                        </div>
                    </div>



                    <div style={{
                        position: 'absolute',
                        zIndex: 3,
                        position: 'absolute',
                        right: "1%",
                        bottom: (Lyrics.length && lyricsAllowed) ? `${configurationRadio?.lyricsFilterSize + 2}%` : '1%',
                    }}>

                    </div>
                </>
            }

            {(!loading && !etatLoading && !end) && <>
                {renderSlides()
                }
                {(startTime && Lyrics.length && lyricsAllowed) &&
                    <div style={{
                        backgroundColor: configurationRadio?.lyricsBgColor && hexToRgba(configurationRadio?.lyricsBgColor, 0.5),
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        height: `${configurationRadio?.lyricsFilterSize}%`,
                        backgroundPosition: "bottom",
                        zIndex: 999,
                        objectFit: 'cover',
                        display: "flex", alignItems: 'center', justifyContent: 'center',
                        overflow: "hidden"
                    }}>
                        <LyricsDisplay Lyrics={Lyrics} startTime={startTime} delai={delai} Taille={configurationRadio?.lyricsSize}
                            Color={configurationRadio?.lyricsColor} Police={configurationRadio?.lyricsPolice}
                        // duree={parseInt((Lyrics[Lyrics.length - 1]?.milliseconds) / 1000)}
                        />


                    </div>
                }
            </>}
            {actualMusic !== "" && (
                <audio ref={audioRef} key={actualMusic} autoPlay loop>
                    <source src={actualMusic} type="audio/mpeg" />
                </audio>
            )}

            {
                (end) &&
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <img
                        alt=''
                        className='animation-disk' src={disque} height={500} width={500} />
                </div>
            }
        </div>
    );
};

export default PreviewSlide;
