import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner = ({ onAccept }) => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="J'accepte"
            cookieName="userConsent"
            style={{ background: "#1C1124" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            onAccept={onAccept}
        >
            Ce site utilise des cookies pour vous garantir la meilleure expérience sur notre site.{" "}
            {/* <a href="/politique-de-confidentialite" style={{ color: "#f1d600" }}>En savoir plus</a> */}
        </CookieConsent>
    );
};

export default CookieConsentBanner;
