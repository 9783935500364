import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Modal, Row, Table, InputGroup, InputGroupAddon, InputGroupText, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axiosClient, { pagination_size } from '../../../axios-client';
import { useDispatch, useSelector } from 'react-redux';
import { addUserRequest, fetchUsersFailure, fetchUsersRequest, fetchUsersSuccess } from '../../store/actions/userActions';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import loadData from '../../../assets/lottie/laod.json'
import Lottie from 'react-lottie';
import { Button } from 'react-bootstrap';
import { FaUserXmark } from 'react-icons/fa6';
import { ToastContainer, toast } from 'react-toastify';

export default function Users() {
    const { loading, users, error } = useSelector(state => state.users);
    const dispatch = useDispatch();
    const [modal_center, setmodal_center] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [emailMessageError, setEmailMessageError] = useState(null); // Initialize nameMessage state variable
    const [deleteId, setDeleteId] = useState(null);

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        tel: '',
        password: '', // Added pas  sword field

        role: 'RADIOOWNER'
    });

    const navigate = useNavigate(); // Initialize useNavigate hook
    const [page, setPage] = useState(1); // State for current page
    const [totalPages, setTotalPages] = useState(1); // State for total pages

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    console.log("usersusers", users);
    useEffect(() => {
        dispatch(fetchUsersRequest());

        axiosClient.get(`users/?page=${page}`) // Include page number in API call
            .then(response => {
                dispatch(fetchUsersSuccess(response.data));
                setTotalPages(Math.ceil(response.data.count / pagination_size)); // Calculate total pages based on count

            })
            .catch(error => {
                dispatch(fetchUsersFailure(error.message));
            });
    }, [dispatch, page]);


    if (loading) {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loadData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Lottie options={defaultOptions} height={120} width={120} />
            </div>
        );
    }
    if (error) {
        return <div>Error: {error}</div>;
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_center() {
        setmodal_center(!modal_center)
        removeBodyCss()
    }



    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const checkEmailAvailability = async (email) => {
        try {
            const response = await axiosClient.get(`check_email/?email=${email}`);
            const responseData = response.data;

            if ('message' in responseData) {
                if (responseData.message === "User with this email already exists.") {
                    return false; // Tag is not available
                } else if (responseData.message === "email is available.") {
                    return true; // Tag is available
                } else {
                    return "Unknown response from server"; // Handle unexpected message
                }
            } else {
                return "Unknown response from server"; // Handle unexpected response
            }
        } catch (error) {
            console.error("Error checking email availability:", error);
            return "Error checking email availability.";
        }
    };


    const handleSubmit = async () => {


        const emailMessage = await checkEmailAvailability(formData.email);

        console.log("emailMessageemailMessage", emailMessage);
        if (emailMessage === false) {
            setEmailMessageError("Une radio avec le même nom existe déjà.");
            // setNameMessagzError("Une radio avec le même nom existe déjà.");

        } else {
            setEmailMessageError(""); // Clear the error message if name is available
        }


        // If either name or tag already exists, prevent form submission
        if (emailMessage === false) {
            return;
        }



        dispatch(addUserRequest(formData)); // Start the loading state
        axiosClient.post('users/', formData)
            .then(response => {
                // Handle success
                console.log('User added successfully:', response.data);
                setmodal_center(false); // Close the modal
                dispatch({ type: 'ADD_USER_SUCCESS' }); // Update loading state to false

                // Fetch updated user data
                axiosClient.get(`users/?page=${page}`) // Fetch updated user data for the current page
                    .then(response => {
                        dispatch(fetchUsersSuccess(response.data));
                        setTotalPages(Math.ceil(response.data.count / 10)); // Update total pages after adding a user

                    })
                    .catch(error => {
                        dispatch(fetchUsersFailure(error.message));
                    });
            })
            .catch(error => {
                alert("hellllllllllllllllllllllllllllllllllll")

                // Log and handle error
                console.error('Error adding user:', error);
                if (error.response) {
                    console.error('Error response status:', error.response.status);
                    console.error('Error response data:', error.response.data);
                } else if (error.request) {
                    console.error('No response received:', error.request);
                } else {
                    console.error('Request setup error:', error.message);
                }

                dispatch({ type: 'ADD_USER_FAILURE', payload: error.message }); // Handle error and update loading state
            });
    };

    const handlePageChange = (event, value) => {
        setPage(value); // Update page state when page changes
    };

    const handleDelete = async (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/users/${id}/`)
            .then((response) => {
                axiosClient.get(`users/?page=${page}`) // Include page number in API call
                    .then(response => {
                        dispatch(fetchUsersSuccess(response.data));
                        setTotalPages(Math.ceil(response.data.count / pagination_size)); // Calculate total pages based on count

                    })
                    .catch(error => {
                        dispatch(fetchUsersFailure(error.message));
                    });
                toast.error("L'utilisateur est supprimée avec succès", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                toggle()
                window.scrollTo(0, 0)
            })
            .catch((error) => {
                console.error(error);
            });

    }
    return (
        <div>
            <ToastContainer />

            <Row className="icons-demo-content">
                <Col xs="12">

                    <Card>
                        <CardBody>
                            <h4 className="card-title" style={{ fontWeight: 'bold' }}>Gestion Utilisateurs</h4>

                            <Row className="mb-3">
                                <Col lg={12} className="d-flex justify-content-end">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            navigate("/addUser")
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                    >
                                        <i style={{ fontSize: '17px', marginRight: "5px" }} className="mdi mdi-account-plus" />
                                        Ajouter un utilisateur
                                    </button>
                                </Col>
                            </Row>     <Row>
                                <Col lg={12}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div style={{ maxHeight: "430px", overflowY: "auto" }} className="table-responsive">
                                                <Table className="table-primary table-striped mb-0">

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Nom</th>
                                                            <th>Prénom</th>
                                                            <th>Adresse email</th>
                                                            <th>numéro téléphone</th>
                                                            <th>Date de création</th>
                                                            <th>Options</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users && users.results && users.results.map(user => (
                                                            <tr key={user.id}>
                                                                <th scope="row">{user.id}</th>
                                                                <td>{user.first_name}</td>
                                                                <td>{user.last_name}</td>
                                                                <td>{user.email}</td>
                                                                <td>{user.tel}</td>
                                                                <td>{new Date(user.date_creation).toLocaleDateString()}</td>
                                                                <td>
                                                                    <Button
                                                                        onClick={() => navigate(`/editUser`, {
                                                                            state: {
                                                                                idUser: user.id
                                                                            }
                                                                        })}
                                                                        variant="outline-primary">Modifier
                                                                    </Button>{' '}
                                                                    {user.role === "RADIOOWNER" &&
                                                                        <Button
                                                                            onClick={() => {
                                                                                setDeleteId(user.id)
                                                                                toggle()
                                                                            }
                                                                            }
                                                                            variant="outline-danger"><FaUserXmark size={20} />

                                                                        </Button>}

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </Col>


                            </Row>
                            <Row>
                                <Col lg={12} className="d-flex justify-content-end">
                                    <Stack spacing={2}>
                                        <Pagination count={totalPages} color="secondary" page={page} onChange={handlePageChange} />
                                    </Stack>
                                </Col>
                            </Row>
                        </CardBody>


                        <Modal
                            isOpen={modal_center}
                            toggle={() => {
                                tog_center()
                            }}
                            centered={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Ajouter un utilisateur</h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setmodal_center(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4">Remplir les champs ci-dessous</CardTitle>
                                        <Row className="mb-3">
                                            <label htmlFor="firstName" className="col-md-2 col-form-label">Prénom</label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="firstName"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label htmlFor="lastName" className="col-md-2 col-form-label">Nom</label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="lastName"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label htmlFor="email" className="col-md-2 col-form-label">Adresse email</label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    id="email"
                                                    style={{ border: emailMessageError ? '2px solid red' : '' }}

                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                                {emailMessageError &&
                                                    <p style={{ color: 'red' }}> {emailMessageError}</p>
                                                }
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label htmlFor="tel" className="col-md-2 col-form-label">Tél</label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    id="tel"
                                                    name="tel"
                                                    value={formData.tel}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label htmlFor="password" className="col-md-2 col-form-label">Mot de passe</label>
                                            <div className="col-md-10">
                                                <InputGroup>
                                                    <Input
                                                        className="form-control"
                                                        type={showPassword ? "text" : "password"}
                                                        id="password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleInputChange}
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText style={{ cursor: 'pointer' }} onClick={toggleShowPassword}>
                                                            <i className={showPassword ? "mdi mdi-eye-off" : "mdi mdi-eye"} />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        {/* <Row className="mb-3">
                                            <label htmlFor="userType" className="col-md-2 col-form-label">User Type</label>
                                            <div className="col-md-10">
                                                <select
                                                    className="form-control"
                                                    id="userType"
                                                    name="role"
                                                    value={formData.role}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="Manager">Manager</option>
                                                    <option value="RadioOwner">Radio Owner</option>
                                                </select>
                                            </div>
                                        </Row> */}
                                        <Row className="mb-3">
                                            <Col lg={12} className="d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    onClick={handleSubmit}
                                                    className="btn btn-primary waves-effect waves-light"
                                                    data-toggle="modal"
                                                    data-target="#myModal"
                                                >
                                                    <i style={{ fontSize: '17px', marginRight: "5px" }} className="mdi mdi-account-plus" />
                                                    Ajouter un utilisateur
                                                </button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Modal>
                    </Card>

                </Col>

            </Row>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Supprimer</ModalHeader>
                <ModalBody>
                    êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle} variant='secondary'>
                        Annuler
                    </Button>{' '}
                    <Button onClick={() => handleDelete(deleteId)} variant='danger' >
                        Supprimer
                    </Button>
                </ModalFooter>
            </Modal>

        </div>
    )
}