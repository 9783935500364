
import { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, Container, DropdownDivider, Form } from 'react-bootstrap';
import axiosClient from '../../../axios-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('')

  const [email, setEmail] = useState("")
  const navigate = useNavigate()

  const { token } = useParams();

  const onSubmit = ev => {
    ev.preventDefault();
    if (email === "") {
      setMessage("Veuillez entrer votre adresse email")

    } else {
      axiosClient.get('reset_email/', { params: { email: email } })
        .then((response) => {
          if (response.status === 203) {
            setMessage(response.data.message)
            
          } else {
            toast.success('Un e-mail de réinitialisation de mot de passe a été envoyé, vérifiez votre boîte de réception', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              });
              setMessage('')
              setTimeout(() => {
                navigate('/login')
            }, 5000);
          }

        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (

    <div  style={{ backgroundColor:"#232939", height: '100vh', width: "100%" }}    >
              <ToastContainer />

      <div style={{ position: 'relative', height: '100vh', width: "100vw", background: 'rgba(0, 0, 0, 0.4)', overflow: 'hidden' }}>
        <div className=' col-xl-4 col-lg-5 col-md-6 col-12' style={{ position: "absolute", top: '50%', left: '50%', transform: "translate(-50%,-50%)" }}>
          <Card className='mx-2 mb-5 p-lg-5 p-md-4 p-3 shadow' style={{ background: 'rgba(255, 255, 255, 0.2)', backdropFilter: 'blur(25px)', borderRadius: 10 }}>
            <Card.Body className='p-1 '>

              <h2 className="fw-bold mb-4 mt-2 text-center" style={{ color: "white" }} >Mot de passe oublié ?</h2>
              <p style={{ color: 'white', fontSize: '1.1rem', marginBottom: '24px' }}>Entrez votre adresse email</p>

              {/* <Row>
          <Col md={6}>
            <Form.Group className='mb-4'>
              <Form.Label>First name</Form.Label>
              <Form.Control type='text' />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-4'>
              <Form.Label>Last name</Form.Label>
              <Form.Control type='text' />
            </Form.Group>
          </Col>
        </Row> */}
              {message &&
                <Alert variant={"danger"}>
                  {message}
                </Alert>
              }

              <Form.Group className='mb-4'>
                <Form.Control placeholder="votre-email@example.com" onChange={(e) => setEmail(e.target.value)} size='lg' type='email' />

              </Form.Group>




              <Button style={{ background: "#29bbe3" }} onClick={onSubmit} className='w-100 mb-2' size='lg'> Demander une réinitialisation</Button>
              <hr style={{ borderColor: "white" }} />
              <div className='d-flex  justify-content-center '>
                <h5 onClick={() => navigate("/login")} style={{ color: "white", textDecoration: 'underline', cursor: 'pointer' }}>Annuler</h5>
              </div>

            </Card.Body>
          </Card>
        </div>
      </div>

    </div>

  )
}

export default ResetPassword