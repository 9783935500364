














import React, { useEffect, useState } from 'react'

import {
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardImg,

    ButtonGroup,
    Button,
    Form,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"
import { Modal, ModalHeader, ModalBody, Tooltip } from 'reactstrap';
import { FaCheck, FaEye, FaTrash } from 'react-icons/fa'; // Import the trash icon from react-icons
import loadData from '../../../assets/lottie/laod.json'


import Dropzone from "react-dropzone"

import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { Divider, Slider, Typography } from '@mui/material';

import Stack from '@mui/material/Stack';
import MUIButton from '@mui/material/Button';
import zoom from "../../../assets/images/animations/zoom.png"
import down from "../../../assets/images/animations/down.png"
import up from "../../../assets/images/animations/up.png"

import fondue from "../../../assets/images/animations/fondue.png"

import scroll from "../../../assets/images/animations/defiler.png"
import aucun from "../../../assets/images/animations/aucun.png"

import countour from "../../../assets/images/animations/countour.png"
import elevation from "../../../assets/images/animations/elevation.png"
import ombres from "../../../assets/images/animations/ombres.png"
import zoomOut from "../../../assets/images/animations/zoomout.png"
import drift from "../../../assets/images/animations/drift.png"
import combine from "../../../assets/images/animations/combine.png"
import role from "../../../assets/images/animations/roll.png"
import snow from "../../../assets/images/snow.jpeg"
import snowc from "../../../assets/images/snowc.jpeg"
import bottomLeft from "../../../assets/images/bottomLeft3.png"
import bottomRight from "../../../assets/images/bottomRight3.png"
import topLeft from "../../../assets/images/topLeft3.png"
import topRight from "../../../assets/images/topRight3.png"
import center from "../../../assets/images/center3.png"

import "./habillage.css"
import { fetchRadioConfigFailure, fetchRadioConfigRequest, fetchRadioConfigSuccess, fetchRadioFailure, fetchRadioRequest, fetchRadioSuccess, fetchSlidesRadioFailure, fetchSlidesRadioRequest, fetchSlidesRadioSuccess } from '../../store/actions/radioActions';
import axiosClient, { link, linkImage } from '../../../axios-client';
import { useStateContext } from '../../../context/ContextProvider';
import Lottie from 'react-lottie';



import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RiSlideshow2Line } from "react-icons/ri";
import PreviewSlide from './Aperçu/PreviewSlide';
import { IoCloseCircleOutline } from 'react-icons/io5';


import { Input } from 'reactstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickSlider from 'react-slick';
export default function ImagesSlider({
    settings,
    selectedFilesZ,
    slidesRadios,
    currentSelectedRadio,
    tooltipOpen,
    toggleTooltip,
    handleAcceptedFilesZ,
    handleEyeButtonClick,
    handleDeleteImagesSlideShow,
    handleSendSlideImages,
    handleDeleteImagesSlideShowExisted,
    radios,
    imagesAdded
}) {
    return (
        <Row className="mb-3  mt-4" >
            <Form>
                <Dropzone
                    onDrop={acceptedFiles => {
                        handleAcceptedFilesZ(acceptedFiles)
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <form encType="multipart/form-data">

                            <div className="dropzone dz-clickable">
                                <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                >
                                    <input type="file"  {...getInputProps()} />
                                    <div className="mb-3">
                                        <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                                    </div>
                                    <h4>Déposer des fichiers ou parcourir</h4>
                                </div>
                            </div>
                        </form>
                    )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFilesZ.length > 0 ? (
                        // Render selected files from upload
                        <>
                            <style>
                                {`
                .slick-next:before, .slick-prev:before {
                    color: black !important; /* Change arrow color to black */
                    font-size: 22px; /* Optional: change size of arrows */
                    background: none; /* Ensure no background */
                }
                .slick-arrow {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .slick-prev {
                    left: -25px; /* Adjust left position if needed */
                }
                .slick-next {
                    right: -25px; /* Adjust right position if needed */
                }
                .slick-dots {
                    bottom: -75px; /* Adjust this value to move dots further from the slider */
                }
                .slick-dots li {
                    margin: 0 5px; /* Adjust spacing between dots if needed */
                }
                .slick-dots li button:before {
                    color: black; /* Dot color */
                }
                `}
                            </style>



                            {selectedFilesZ.length === 1

                                ? (selectedFilesZ.map((f, i) => {
                                    // setGlobalPreviewUrl(f.preview)
                                    // globalPreviewUrl = f.preview

                                    return (

                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                        >
                                            <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            height="80"
                                                            className="avatar-sm rounded bg-light"
                                                            alt={f.name}
                                                            src={f.preview}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Link to="#" className="text-muted font-weight-bold">
                                                            {f.name}
                                                        </Link>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={() => {
                                                            handleEyeButtonClick(f.preview)
                                                        }} className="btn btn-info btn-sm ml-8">
                                                            <FaEye />
                                                        </Button>
                                                        <Button style={{ marginLeft: "15px" }} onClick={() => handleDeleteImagesSlideShow(i)} className="btn btn-danger btn-sm">
                                                            <FaTrash />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                    )



                                }))


                                : (
                                    <SlickSlider {...settings}>
                                        {selectedFilesZ.map((f, i) => {
                                            const truncatedName = f.name.length > 15 ? f.name.slice(0, 15) + '...' : f.name;
                                            return (
                                                <div key={i} style={{ padding: '0 10px' }}>
                                                    <Card


                                                        style={{ marginLeft: '13px' }}
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}
                                                    >
                                                        <div className="p-2">
                                                            <Row className="align-items-center">
                                                                <Col className="col-auto">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        height="80"
                                                                        className="avatar-sm rounded bg-light"
                                                                        alt={f.name}
                                                                        src={f.preview}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Link to="#" className="text-muted font-weight-bold">
                                                                        <span
                                                                            id={`tooltip-${i}`}
                                                                            onMouseEnter={() => toggleTooltip(i)}
                                                                            onMouseLeave={() => toggleTooltip(null)}
                                                                        >
                                                                            {truncatedName}
                                                                        </span>
                                                                        {tooltipOpen === i && (
                                                                            <Tooltip
                                                                                placement="top"
                                                                                isOpen={true}
                                                                                target={`tooltip-${i}`}
                                                                                toggle={() => toggleTooltip(i)}
                                                                            >
                                                                                {f.name}
                                                                            </Tooltip>
                                                                        )}
                                                                    </Link>
                                                                </Col>
                                                                <Col className="col-auto">
                                                                    <Button onClick={() => handleEyeButtonClick(f.preview)} className="btn btn-info btn-sm">
                                                                        <FaEye />
                                                                    </Button>
                                                                    <Button style={{ marginLeft: "15px" }} onClick={() => handleDeleteImagesSlideShow(i)} className="btn btn-danger btn-sm">
                                                                        <FaTrash />
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Card>
                                                </div>
                                            );
                                        })}
                                    </SlickSlider>
                                )


                            }


                        </>
                    ) : (
                        // Render selected radio logo or corresponding slide radios
                        <>
                            <style>
                                {`
                .slick-next:before, .slick-prev:before {
                    color: black !important; /* Change arrow color to black */
                    font-size: 22px; /* Optional: change size of arrows */
                    background: none; /* Ensure no background */
                }
                .slick-arrow {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .slick-prev {
                    left: -25px; /* Adjust left position if needed */
                }
                .slick-next {
                    right: -25px; /* Adjust right position if needed */
                }
                .slick-dots {
                    bottom: -40px; /* Adjust this value to move dots further from the slider */
                }
                .slick-dots li {
                    margin: 0 5px; /* Adjust spacing between dots if needed */
                }
                .slick-dots li button:before {
                    color: black; /* Dot color */
                }
                `}
                            </style>





                            {slidesRadios.length === 1
                                ? (

                                    <>
                                        {currentSelectedRadio ? (
                                            // Render corresponding slide radios where radio attribute matches currentSelectedRadio
                                            slidesRadios
                                                .filter(correspondingSlideRadio => correspondingSlideRadio.radio === currentSelectedRadio)
                                                .map((correspondingSlideRadio, index) => (
                                                    <div key={index}>
                                                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={"image"}
                                                                            src={correspondingSlideRadio.image}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <Link to="#" className="text-muted font-weight-bold">
                                                                            {radios.find(radio => radio.id === correspondingSlideRadio.radio)?.name}
                                                                        </Link>
                                                                    </Col>
                                                                    <Col className="col-auto">
                                                                        <Button onClick={() => {

                                                                            handleEyeButtonClick(correspondingSlideRadio.image)
                                                                        }} className="btn btn-info btn-sm ml-2"> {/* Added ml-2 for left margin */}
                                                                            <FaEye />
                                                                        </Button>
                                                                        <Button style={{ marginLeft: "15px" }} onClick={() => handleDeleteImagesSlideShowExisted(index)} className="btn btn-danger btn-sm ml-7"> {/* Added ml-2 for left margin */}
                                                                            <FaTrash />
                                                                        </Button>
                                                                    </Col>
                                                                    {/* Add more elements to display additional information if needed */}
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                ))
                                        ) : (
                                            // Render selected radio logo
                                            null // or handle the case when no radio is selected
                                        )}
                                    </>
                                )
                                : (
                                    <SlickSlider {...settings}>

                                        {currentSelectedRadio ? (









                                            // Render corresponding slide radios where radio attribute matches currentSelectedRadio
                                            slidesRadios
                                                .filter(correspondingSlideRadio => correspondingSlideRadio.radio === currentSelectedRadio)
                                                .map((correspondingSlideRadio, index) => {

                                                    // { radios.find(radio => radio.id === correspondingSlideRadio.radio)?.name }

                                                    const truncatedName = radios.find(radio => radio.id === correspondingSlideRadio.radio)?.name.length > 15 ? radios.find(radio => radio.id === correspondingSlideRadio.radio)?.name.slice(0, 15) + '...' : radios.find(radio => radio.id === correspondingSlideRadio.radio)?.name;


                                                    return (
                                                        <div key={index} style={{ padding: '0 10px' }}>

                                                            <Card style={{ marginLeft: '13px' }}
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                alt={"image"}
                                                                                src={correspondingSlideRadio.image}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Link to="#" className="text-muted font-weight-bold">
                                                                                {truncatedName}
                                                                            </Link>
                                                                        </Col>
                                                                        <Col className="col-auto">
                                                                            <Button onClick={() => {

                                                                                handleEyeButtonClick(correspondingSlideRadio.image)
                                                                            }} className="btn btn-info btn-sm ml-2"> {/* Added ml-2 for left margin */}
                                                                                <FaEye />
                                                                            </Button>
                                                                            <Button style={{ marginLeft: "15px" }} onClick={() => handleDeleteImagesSlideShowExisted(index)} className="btn btn-danger btn-sm ml-7"> {/* Added ml-2 for left margin */}
                                                                                <FaTrash />
                                                                            </Button>
                                                                        </Col>
                                                                        {/* Add more elements to display additional information if needed */}
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    )

                                                })
                                        ) : (
                                            // Render selected radio logo
                                            null // or handle the case when no radio is selected
                                        )}


                                    </SlickSlider>
                                )


                            }

                        </>

                    )}
                    {/* Render send button if files are selected */}
                    {selectedFilesZ.length > 0 && !imagesAdded && (
                        <>
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                    <Button style={{ marginTop: "20px", width: "100px" }} onClick={handleSendSlideImages} className="btn btn-success btn-sm">
                                        <FaCheck /> Confirmer                                                                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </div>


            </Form>
        </Row>

    )
}
