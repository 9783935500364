import { createContext, useContext, useState, useEffect } from "react";
import axiosClient from "../axios-client";
import { Navigate } from "react-router-dom";
const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setUser: () => { },
  setToken: () => { },
  setNotification: () => { },
  setIsLoading: () => { },

});

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, _setToken] = useState(localStorage.getItem("U_T"));

  //const [notification, _setNotification] = useState('');
  const [isLoading, _setIsLoading] = useState(false);


  useEffect(() => {
  if (token) {
    axiosClient
      .get("auth/user/")
      .then(({ data }) => {
        setUser(data);
        // console.log(data);
        
      })
      .catch((err) => {
        console.log(err);
      });
  }
}, [token]);

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("U_T", token);
    } else {
      localStorage.removeItem("U_T");
    }
  };


  const setIsLoading = (v) => {
    _setIsLoading(v);
  };


  


  return (
    <StateContext.Provider
      value={{

        user,
        setUser,
        token,
        setToken,
        setIsLoading,
        isLoading,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
