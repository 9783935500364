export const FETCH_MUSIQUE_REQUEST = 'FETCH_MUSIQUE_REQUEST';
export const FETCH_MUSIQUE_SUCCESS = 'FETCH_MUSIQUE_SUCCESS';
export const FETCH_MUSIQUE_FAILURE = 'FETCH_MUSIQUE_FAILURE';

export const ADD_MUSIQUE_REQUEST = 'ADD_MUSIQUE_REQUEST';
export const ADD_MUSIQUE_SUCCESS = 'ADD_MUSIQUE_SUCCESS';
export const ADD_MUSIQUE_FAILURE = 'ADD_MUSIQUE_FAILURE';

export const fetchMusiqueRequest = () => ({
    type: FETCH_MUSIQUE_REQUEST
});

export const fetchMusiqueSuccess = (musiques) => ({
    type: FETCH_MUSIQUE_SUCCESS,
    payload: musiques
});

export const fetchMusiqueFailure = (error) => ({
    type: FETCH_MUSIQUE_FAILURE,
    payload: error
});

export const addMusiqueRequest = (musiqueData) => ({
    type: ADD_MUSIQUE_REQUEST,
    payload: musiqueData
});

export const addMusiqueSuccess = () => ({
    type: ADD_MUSIQUE_SUCCESS
});

export const addMusiqueFailure = (error) => ({
    type: ADD_MUSIQUE_FAILURE,
    payload: error
});
