// reducers/radio.js
import { FETCH_RADIO_REQUEST, FETCH_RADIO_SUCCESS, FETCH_RADIO_FAILURE, ADD_RADIO_REQUEST, ADD_RADIO_SUCCESS, ADD_RADIO_FAILURE, FETCH_SLIDES_RADIO_REQUEST, FETCH_SLIDES_RADIO_SUCCESS, FETCH_SLIDES_RADIO_FAILURE, FETCH_RADIO_CONFIG_SUCCESS, FETCH_RADIO_CONFIG_REQUEST, FETCH_RADIO_CONFIG_FAILURE } from '../actions/radioActions';

const initialState = {
    loading: false,
    radios: [],
    slidesRadios: [], // Add slidesRadios array
    configurations: [], // Add configurations array
    error: ''
};

const radioReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RADIO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_RADIO_SUCCESS:
            return {
                ...state,
                loading: false,
                radios: action.payload,
                error: ''
            };
        case FETCH_RADIO_FAILURE:
            return {
                ...state,
                loading: false,
                radios: [],
                error: action.payload
            };
        case ADD_RADIO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_RADIO_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_RADIO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        // Reducer cases for slidesRadio
        case FETCH_SLIDES_RADIO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_SLIDES_RADIO_SUCCESS:
            return {
                ...state,
                loading: false,
                slidesRadios: action.payload,
                error: ''
            };
        case FETCH_SLIDES_RADIO_FAILURE:
            return {
                ...state,
                loading: false,
                slidesRadios: [],
                error: action.payload
            };
        // Reducer cases for radio configurations
        case FETCH_RADIO_CONFIG_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_RADIO_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                configurations: action.payload,
                error: ''
            };
        case FETCH_RADIO_CONFIG_FAILURE:
            return {
                ...state,
                loading: false,
                configurations: [],
                error: action.payload
            };
        default:
            return state;
    }
};

export default radioReducer;
