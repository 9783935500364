import { Link } from '@mui/material';
import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';
import { useNavigate } from 'react-router-dom/dist';
import img from "./bg.avif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Login() {

  const titleColor = "white";
  const textColor = "gray.400";
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { setToken, isLoading, setIsLoading } = useStateContext()
  const [message, setMessage] = useState('')
  const navigate = useNavigate()

  const onSubmit = ev => {
    ev.preventDefault()
    setIsLoading(true)
    const payload = {
      email,
      password,
    }

    axiosClient.post('token/user/', payload)
      .then(({ data }) => {
        setToken(data.access);
        setIsLoading(false);
        // localStorage.setItem("ACCESS_TOKEN", data.access)
        localStorage.setItem("role", data.role)

        navigate('/')
      })
      .catch((err) => {
        const response = err.response;
        
        if(response.data.message === "Votre compte est désactivé")
        {setMessage(response.data.message);}
        else 
        setMessage("Email ou mot de passe incorrect");

        setIsLoading(false)
        console.error("error: ", err);
      })

  }
  const [showPassword, setShowPassword] = useState(false);
  const isFormFilled = email && password;

  return (
    <div className="bg-image" style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover',backgroundRepeat:"no-repeat", height: '100vh' , width:"100%" }}    >
      <ToastContainer />
      <div className='' style={{position:'relative' , height: '100vh' ,width:"100vw",background: 'rgba(0, 0, 0, 0.4)' ,overflow:'hidden'}}>
        <div className=' col-xl-4 col-lg-5 col-md-6 col-12' style={{ position:"absolute" ,top:'50%' , left:'50%' ,transform:"translate(-50%,-50%)"}}>
        
        <Card className='mx-2 mb-5 p-lg-5 p-md-4 p-3 shadow' style={{  background: 'rgba(255, 255, 255, 0.2)', backdropFilter: 'blur(25px)', borderRadius:10}}>
          <Card.Body className='p-1 '>

            <h1 className="fw-bold mb-4 mt-2 text-center" style={{color:"white"}} >Se connecter</h1>

            {/* <Row>
            <Col md={6}>
              <Form.Group className='mb-4'>
                <Form.Label>First name</Form.Label>
                <Form.Control type='text' />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className='mb-4'>
                <Form.Label>Last name</Form.Label>
                <Form.Control type='text' />
              </Form.Group>
            </Col>
          </Row> */}
            {message &&
              <Alert variant={"danger"}>
                {message}
              </Alert>
            }

            <Form.Group className='mb-4'>
              <Form.Label style={{color:"white"}} >Email</Form.Label>
              <Form.Control  placeholder='Entrez votre email' onChange={(e) => setEmail(e.target.value)} size='lg' type='email' />
            </Form.Group>

            <Form.Group className='mb-4'>
              <Form.Label style={{color:"white"}}>Mot de passe</Form.Label>
              <Form.Control placeholder='Entrez votre mot de passe' onChange={(e) => setPassword(e.target.value)} size='lg' type='password' />
            </Form.Group>

            <div className='d-flex  mb-4'>
              <span onClick={()=> navigate("/reset_password")} style={{color:"white", textDecoration:'underline' , cursor:'pointer'}}>Mot de passe oublié ?</span>
            </div>

            <Button style={{background:"#29bbe3" }} onClick={onSubmit} isLoading={isLoading} disabled={!isFormFilled} className='w-100 mb-4' size='lg'>  {isLoading ? <Spinner size="sm" /> : 'Connexion'}</Button>



          </Card.Body>
        </Card>
        </div>
      </div>

    </div>
  );
}

export default Login;
