import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Card, CardBody, Image, Alert, InputGroup } from "react-bootstrap";
import { useStateContext } from "../../../context/ContextProvider";
import axiosClient, { linkHome } from "../../../axios-client";
import { Input, InputGroupAddon, InputGroupText, Media } from "reactstrap";
import { FaPencilAlt } from "react-icons/fa";
import Resizer from "react-image-file-resizer";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
const EditUser = () => {

    const { user } = useStateContext()
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [tel, setTel] = useState("");
    const [genre, setGenre] = useState("");
    const [email, setEmail] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [validated, setValidated] = useState(false);

    const [selectedImage, setSelectedImage] = useState()
    const [selectedImg, setSelectedImg] = useState()
    const [image, setImage] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation()


    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [newPassword, setNewPassword] = useState('');

    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate()

    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
    const [editUser, setEditUser] = useState([])
    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });
    };
    const handleChangeImage = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedImg(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedImage(imageFile);
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {

        axiosClient.get(`/users/${location.state.idUser}/`)
            .then(({ data }) => {
                setEditUser(data)
            })
        if (selectedImg) {
            setImage(URL.createObjectURL(selectedImg))
        }
        else {
            setSelectedImg()
        }

    }, [selectedImage,editUser]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        if (firstName) { formData.append("first_name", firstName); }
        if (lastName) { formData.append("last_name", lastName); }
        if (email) { formData.append("email", email); }
        if (genre) { formData.append("gender", genre); }

        if (country) { formData.append("pays", country); }
        if (city) { formData.append("ville", city); }
        if (street) { formData.append("rue", street); }
        if (tel) { formData.append("tel", tel); }
        if (zipCode) { formData.append("code_postal", zipCode); }
        if (selectedImage) formData.append("image", selectedImage);


        try {
            const response = await axiosClient.put(`/update_user/${editUser.id}/`, formData)
            if (response.data.message) {
                toast.success("L'utilisateur est modifié avec succès", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                window.scrollTo(0, 0)
            }
        } catch (err) {
            console.log(err);
        }

    };

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(newPassword)) {
            missingRequirements.push("caractère spécial");
        } else if (!lowercaseRegex.test(newPassword)) {
            missingRequirements.push("minuscule");
        } else if (!uppercaseRegex.test(newPassword)) {
            missingRequirements.push("majuscule");
        } else if (!digitRegex.test(newPassword)) {
            missingRequirements.push("chiffres");
        } else if (!minLengthRegex.test(newPassword)) {
            missingRequirements.push("longueur minimale de 8 caractères");
        }

        if (missingRequirements.length > 0) {
            const missingText = `Vous avez besoin de ${missingRequirements.join(", ")} dans votre mot de passe.`;
            setPasswordStrength(missingText);
            setPasswordColor('#ff7070');
        } else {
            setPasswordStrength('Le mot de passe est correct!');
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [newPassword]);


    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push('Le mot de passe doit comporter au moins 8 caractères.');
        }

        if (!digitRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins un chiffre.');
        }

        if (!lowercaseRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins une lettre minuscule.');
        }

        if (!uppercaseRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins une lettre majuscule.');
        }
        if (!specialchar.test(password)) {
            errors.push('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).');
        }
        if (password.length > 20) {
            errors.push('Le mot de passe ne doit pas dépasser 20 caractères.');
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };


    return (
        <React.Fragment>
            <ToastContainer />

            <h3 className=" mb-4 mt-4">Modifier l'utilisateur</h3>
            <Card className="container px-5 lg-px-1 py-3">
                <CardBody className="container px-5">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col style={{alignContent:"center"}} md="3">
                            <Media>
                                <div className="position-relative" style={{ width: 180, height: 180, marginRight: 10 }}>
                                    <Avatar
                                        style={{ objectFit: "cover" ,borderRadius:"5%"}}
                                        sx={{ width: 180, height: 180 }}
                                        src={image ? image : editUser.image }
                                        variant="square"
                                    />
                                    <label htmlFor="imageInput" className="position-absolute" style={{
                                        cursor: 'pointer',
                                        bottom: -15,
                                        right: -10,
                                        border: "3px solid white",
                                        borderRadius: "50%"
                                    }}>
                                        <input
                                            id="imageInput"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={handleChangeImage}
                                        />
                                        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#232939', borderRadius: '50%', width: 30, height: 30 }}>
                                            <FaPencilAlt size={15} color='#fff' />
                                        </div>
                                    </label>
                                </div>
                               
                            </Media>
                            </Col>
                            <Col md="9">
                                <Row className="mb-3 gap-2 gap-md-0">
                                    <Form.Group as={Col} md="12" controlId="validationCustomEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder="Entrez l'email"
                                            defaultValue={editUser.email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Form.Group>


                                </Row>
                                <Row className="mb-3 gap-2 gap-md-0">

                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>Nom</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Entrez le nom"
                                            defaultValue={editUser.last_name}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>Prénom</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Entrez le prénom"
                                            defaultValue={editUser.first_name}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3 gap-2 gap-md-0">
                            <Form.Group as={Col} md="6" controlId="validationCustomTel">
                                <Form.Label>Numéro de telephone</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Entrez le numéro de telephone"
                                    defaultValue={editUser.tel}
                                    onChange={(e) => setTel(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustomGenre">
                                <Form.Label>Genre</Form.Label>
                                <div className="d-flex gap-5">
                                    <Form.Check
                                        type="radio"
                                        label="Homme"
                                        name="genre"
                                        id="homme"
                                        value="Homme"
                                        checked={genre ? genre === "Homme" : editUser.gender === "Homme"}
                                        onChange={() => setGenre("Homme")}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Femme"
                                        name="genre"
                                        id="femme"
                                        value="Femme"
                                        checked={genre ? genre === "Femme" : editUser.gender === "Femme"}
                                        onChange={() => setGenre("Femme")}
                                    />
                                </div>
                            </Form.Group>
                        </Row>
                            </Col>
                        </Row>


                       

                        <h4 className="card-title mb-4 mt-4 ">Adresse personnelle</h4>
                        <Row className="mb-3 gap-2 gap-md-0">
                            <Form.Group as={Col} md="6" controlId="validationCustomStreet">
                                <Form.Label>Rue</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Entrez la rue"
                                    defaultValue={editUser.rue}
                                    onChange={(e) => setStreet(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustomCity">
                                <Form.Label>Ville</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Entrez la ville"
                                    defaultValue={editUser.ville}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3 gap-2 gap-md-0">
                            <Form.Group as={Col} md="6" controlId="validationCustomCountry">
                                <Form.Label>Pays</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Entrez le pays"
                                    defaultValue={editUser.pays}
                                    onChange={(e) => setCountry(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustomZip">
                                <Form.Label>Code postal</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Entrez un code postal"
                                    defaultValue={editUser.code_postal}
                                    onChange={(e) => setZipCode(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <Button type="submit">Enregistrer</Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>


        </React.Fragment>
    );
};

export default EditUser;
