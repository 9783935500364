import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import GlisserSlide from './components/glisser/GlisserSlide';
import NewAnimation from './components/newAnimations/NewAnimation';





function App() {
  const [streamUrl, setStreamUrl] = useState("");

  return (

    <div style={{
      height: '100%',
      width: '100wh',
    }}>
      <NewAnimation setStreamUrl={setStreamUrl} />
      {/* {streamUrl !== "" &&
        <>
          <audio key={streamUrl} autoPlay loop>
            <source src={streamUrl} type="audio/mpeg" />
          </audio>
        </>} */}
    </div>

  );
}

export default App;
