








import React, { useEffect, useState } from 'react'

import {
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardImg,

    ButtonGroup,
    Button,
    Form,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"
import { Modal, ModalHeader, ModalBody, Tooltip } from 'reactstrap';
import { FaCheck, FaEye, FaTrash } from 'react-icons/fa'; // Import the trash icon from react-icons
import loadData from '../../../assets/lottie/laod.json'


import Dropzone from "react-dropzone"

import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { Divider, Slider, Typography } from '@mui/material';

import Stack from '@mui/material/Stack';
import MUIButton from '@mui/material/Button';
import zoom from "../../../assets/images/animations/zoom.png"
import down from "../../../assets/images/animations/down.png"
import up from "../../../assets/images/animations/up.png"

import fondue from "../../../assets/images/animations/fondue.png"

import scroll from "../../../assets/images/animations/defiler.png"
import aucun from "../../../assets/images/animations/aucun.png"

import countour from "../../../assets/images/animations/countour.png"
import elevation from "../../../assets/images/animations/elevation.png"
import ombres from "../../../assets/images/animations/ombres.png"
import zoomOut from "../../../assets/images/animations/zoomout.png"
import drift from "../../../assets/images/animations/drift.png"
import combine from "../../../assets/images/animations/combine.png"
import role from "../../../assets/images/animations/roll.png"
import snow from "../../../assets/images/snow.jpeg"
import snowc from "../../../assets/images/snowc.jpeg"
import bottomLeft from "../../../assets/images/bottomLeft3.png"
import bottomRight from "../../../assets/images/bottomRight3.png"
import topLeft from "../../../assets/images/topLeft3.png"
import topRight from "../../../assets/images/topRight3.png"
import center from "../../../assets/images/center3.png"

import "./habillage.css"
import { fetchRadioConfigFailure, fetchRadioConfigRequest, fetchRadioConfigSuccess, fetchRadioFailure, fetchRadioRequest, fetchRadioSuccess, fetchSlidesRadioFailure, fetchSlidesRadioRequest, fetchSlidesRadioSuccess } from '../../store/actions/radioActions';
import axiosClient, { link, linkImage } from '../../../axios-client';
import { useStateContext } from '../../../context/ContextProvider';
import Lottie from 'react-lottie';



import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RiSlideshow2Line } from "react-icons/ri";
import PreviewSlide from './Aperçu/PreviewSlide';
import { IoCloseCircleOutline } from 'react-icons/io5';


import { Input } from 'reactstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickSlider from 'react-slick';
import ImagesSlider from './ImagesSlider';
export default function LowerSection({
    walletH, setWalletH, walletW, setWalletW, walletPosition,
    handleWalletPositionToggle, topLeft, topRight, bottomLeft, bottomRight,
    lyricsSize, setLyricsSize, lyricsFilterSize, setLyricsFilterSize,
    lyricsPolice, setDropAlignT, dropAlignT, searchTerm__, setSearchTerm__,
    filteredFonts__, handleFontSelectT, lyricsColor, setLyricsColor,
    cConfig, setLyricsBgColor
}) {
    return (
        <Row>
            <Card className="col-md-12">
                <Row>

                    <Col md={6}>
                        <CardBody>
                            <CardTitle className="h4">Pochette disque</CardTitle>
                            <hr style={{ borderTop: '2px solid #999' }} />

                            <Row className="mb-3  mt-4">
                                <label
                                    htmlFor="example-number-input"
                                    className="col-md-3 col-form-label"
                                >
                                    Hauteur(px)
                                </label>
                                <div className="col-md-8">
                                    <input style={{ marginLeft: '30px', borderRadius: '9px', backgroundColor: '#f2f6f9', borderColor: '3px solid  #f2f6f9' }}
                                        className="form-control"
                                        type="number"
                                        value={walletH}
                                        onChange={(e) => {
                                            // Convert the input value to a number
                                            let newValue = parseInt(e.target.value);

                                            // // Check if the new value is greater than 50, if so, set it to 50
                                            // if (newValue > 50) {
                                            //     newValue = 50;
                                            // }

                                            // Update the state with the new value
                                            setWalletH(newValue);
                                        }}
                                        id="example-number-input"
                                    // max={50} // This limits the input to a maximum value of 50
                                    />
                                </div>

                            </Row>
                            <Row className="mb-3  mt-4">
                                <label
                                    htmlFor="example-number-input"
                                    className="col-md-3 col-form-label"
                                >
                                    Largeur(px)
                                </label>
                                <div className="col-md-8">
                                    <input style={{ marginLeft: '30px', borderRadius: '9px', backgroundColor: '#f2f6f9', borderColor: '3px solid  #f2f6f9' }}
                                        className="form-control"
                                        type="number"
                                        value={walletW}
                                        onChange={(e) => {
                                            // Convert the input value to a number
                                            let newValue = parseInt(e.target.value);

                                            // // Check if the new value is greater than 50, if so, set it to 50
                                            // if (newValue > 50) {
                                            //     newValue = 50;
                                            // }

                                            // Update the state with the new value
                                            setWalletW(newValue);
                                        }}
                                        id="example-number-input"
                                    // max={50} // This limits the input to a maximum value of 50
                                    />
                                </div>

                            </Row>



                            <Row className="mb-3  mt-4">
                                <label
                                    htmlFor="example-number-input"
                                    className="col-md-3 col-form-label  mt-3"
                                >
                                    Position
                                </label>
                                <div className="col-md-8" >
                                    {/* <ButtonGroup className="w-50" style={{ marginLeft: '30px' }} size="md" role="group" aria-label="position">
                                                                    <Button
                                                                        style={{ backgroundColor: walletPosition.includes("bottom") ? "#11708a" : "" }}
                                                                        type="button"
                                                                        color="info"
                                                                        onClick={() => handleWalletPositionToggle("bottom")}
                                                                    >
                                                                        bas
                                                                    </Button>{" "}
                                                                    <Button
                                                                        style={{ backgroundColor: walletPosition.includes("top") ? "#11708a" : "" }}
                                                                        type="button"
                                                                        color="info"
                                                                        onClick={() => handleWalletPositionToggle("top")}
                                                                    >
                                                                        haut
                                                                    </Button>{" "}
                                                                </ButtonGroup>

                                                                <ButtonGroup className="w-50 mt-2" style={{ marginLeft: '30px' }} size="md" role="group" aria-label="position">
                                                                    <Button
                                                                        style={{ backgroundColor: walletPosition.includes("left") ? "#11708a" : "" }}
                                                                        type="button"
                                                                        color="info"
                                                                        onClick={() => handleWalletPositionToggle("left")}
                                                                    >
                                                                        gauche
                                                                    </Button>{" "}
                                                                    <Button
                                                                        style={{ backgroundColor: walletPosition.includes("right") ? "#11708a" : "" }}
                                                                        type="button"
                                                                        color="info"
                                                                        onClick={() => handleWalletPositionToggle("right")}
                                                                    >
                                                                        droite
                                                                    </Button>{" "}
                                                                </ButtonGroup> */}

                                    <div style={{ display: 'flex', marginLeft: "30px" }}>
                                        <CardImg
                                            className="img-fluid"
                                            src={topLeft}
                                            alt="Lexa"
                                            style={{
                                                width: '60px', height: '60px', marginRight: "10px", border: walletPosition.includes('top') && walletPosition.includes('left') ? '2px solid blue' : '1px solid black'

                                            }}
                                            onClick={() => handleWalletPositionToggle("topLeft")}
                                        />
                                        <CardImg
                                            className="img-fluid"
                                            src={topRight}
                                            alt="Lexa"
                                            style={{
                                                width: '60px', height: '60px', marginRight: "10px", border: walletPosition.includes('top') && walletPosition.includes('right') ? '2px solid blue' : '1px solid black'
                                            }}
                                            onClick={() => handleWalletPositionToggle("topRight")}
                                        />
                                        <CardImg
                                            className="img-fluid"
                                            src={bottomLeft}
                                            alt="Lexa"
                                            style={{
                                                width: '60px', height: '60px', marginRight: "10px", border: walletPosition.includes('bottom') && walletPosition.includes('left') ? '2px solid blue' : '1px solid black'
                                            }}
                                            onClick={() => handleWalletPositionToggle("bottomLeft")}
                                        />
                                        <CardImg
                                            className="img-fluid"
                                            src={bottomRight}
                                            alt="Lexa"
                                            style={{
                                                width: '60px', height: '60px', marginRight: "10px", border: walletPosition.includes('bottom') && walletPosition.includes('right') ? '2px solid blue' : '1px solid black'
                                            }}
                                            onClick={() => handleWalletPositionToggle("bottomRight")}
                                        />
                                    </div>
                                </div>


                            </Row>





                        </CardBody>
                    </Col>
                    <Col md={6}>
                        <CardBody>
                            <CardTitle className="h4">Parole</CardTitle>

                            <Row className="mb-3 mt-4">
                                <label htmlFor="example-number-input" className="col-md-3 col-form-label">
                                    Taille texte(px)
                                </label>
                                <div className="col-md-8">
                                    <input
                                        style={{
                                            marginLeft: '30px',
                                            borderRadius: '9px',
                                            backgroundColor: '#f2f6f9',
                                            borderColor: '3px solid #f2f6f9'
                                        }}
                                        className="form-control"
                                        type="number"
                                        value={lyricsSize}
                                        onChange={(e) => setLyricsSize(e.target.value)} // Limit the value to 50
                                        id="example-number-input"
                                    />
                                </div>
                            </Row>

                            <Row className="mb-3 mt-4">
                                <label htmlFor="example-number-input" className="col-md-3 col-form-label">
                                    Taille Filtre(%)
                                </label>
                                <div className="col-md-8">
                                    <input
                                        style={{
                                            marginLeft: '30px',
                                            borderRadius: '9px',
                                            backgroundColor: '#f2f6f9',
                                            borderColor: '3px solid #f2f6f9'
                                        }}
                                        className="form-control"
                                        type="number"
                                        value={lyricsFilterSize}
                                        onChange={(e) => setLyricsFilterSize(e.target.value)} // Limit the value to 50
                                        id="example-number-input"
                                    />
                                </div>
                            </Row>


                            <Row className="mb-3 mt-4">
                                <label htmlFor="example-number-input" className="col-md-3 col-form-label">Police</label>
                                <div className="col-md-8" style={{ marginLeft: '30px' }}>
                                    <ButtonDropdown
                                        style={{ width: '340px' }}
                                        className="btn-group mt-1"
                                        isOpen={dropAlignT}
                                        direction="right"
                                        toggle={() => setDropAlignT(!dropAlignT)}
                                    >
                                        <DropdownToggle caret color="secondary" className="btn btn-secondary">
                                            {lyricsPolice} <i className="mdi mdi-chevron-down" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-right-custom with-scroll">
                                            <div style={{ padding: '10px', width: '300px' }}>
                                                <Input
                                                    type="text"
                                                    placeholder="Search fonts..."
                                                    value={searchTerm__}
                                                    onChange={(e) => setSearchTerm__(e.target.value)}
                                                />
                                            </div>
                                            {filteredFonts__.map((font, index) => (
                                                <DropdownItem key={index} onClick={() => handleFontSelectT(font)}>
                                                    <span style={{ fontFamily: font }}>{font}</span>
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </div>
                            </Row>
                            <Row className="mb-3  mt-4">
                                <label
                                    htmlFor="example-number-input"
                                    className="col-md-3 col-form-label"
                                >
                                    Couleur
                                </label>
                                <div className="col-md-8" >
                                    <input
                                        style={{
                                            marginLeft: '30px',
                                            width: "100%"
                                        }}
                                        className="form-control form-control-color mw-100"
                                        type="color"
                                        defaultValue={lyricsColor} // Set the defaultValue to the current value of titleColor
                                        onChange={(e) => setLyricsColor(e.target.value)


                                        }

                                        id="example-color-input"

                                    />
                                </div>
                            </Row>


                            <Row className="mb-3  mt-4">
                                <label
                                    htmlFor="example-number-input"
                                    className="col-md-3 col-form-label"
                                >
                                    Arrière plan
                                </label>
                                <div className="col-md-8" >
                                    <input
                                        style={{
                                            marginLeft: '30px',
                                            width: "100%"
                                        }}
                                        className="form-control form-control-color mw-100"
                                        type="color"
                                        defaultValue={cConfig && cConfig.lyricsBgColor} // Set the defaultValue to the current value of titleColor
                                        onChange={(e) => setLyricsBgColor(e.target.value)


                                        }

                                        id="example-color-input"

                                    />
                                </div>
                            </Row>

                        </CardBody>
                    </Col>
                </Row>
            </Card>




        </Row>
    )
}
