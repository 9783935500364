







// import exifr from 'exifr';




import React, { useState } from 'react'
import { Card, CardBody, Row, Table, ModalBody, Col, Modal, ModalFooter, ModalHeader, Media, Label, Form, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap'
// import tune from "../../../assets/images/tune.png"
import { useDispatch, useSelector } from 'react-redux';
import { fetchRadioFailure, fetchRadioRequest, fetchRadioSuccess } from '../../store/actions/radioActions';
import axiosClient from '../../../axios-client';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import loadData from '../../../assets/lottie/laod.json'
import { Avatar, CircularProgress, LinearProgress, Divider, IconButton, Pagination, Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { MdCheckCircle, MdClose, MdPlayArrow } from "react-icons/md";
import { IoTrash } from "react-icons/io5";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaMusic } from 'react-icons/fa';
// import Resizer from "react-image-file-resizer";
// import { RiImageAddFill } from "react-icons/ri";
import { Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Dropzone from "react-dropzone"

import 'react-toastify/dist/ReactToastify.css';
// import { useStateContext } from '../../../context/ContextProvider';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import { FaRadio } from "react-icons/fa6";
import { addMusiqueFailure, addMusiqueRequest, addMusiqueSuccess, fetchMusiqueFailure, fetchMusiqueRequest, fetchMusiqueSuccess } from '../../store/actions/musiqueActions';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Bounce, ToastContainer, toast } from 'react-toastify';

// import MdModal from 'react-bootstrap/Modal';
import MusicLyrics from './LyricsLecture';
import { AiFillAudio, AiOutlineClose } from 'react-icons/ai';
import { TextField, InputAdornment } from '@mui/material';
import JSZip from 'jszip';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useStateContext } from '../../../context/ContextProvider';


export default function MImport() {
    const [modal_center, setmodal_center] = useState(false)
    const [musicLoad, setMusicLoad] = useState(false);
    const animatedComponents = makeAnimated();
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [sSeparators, setSSeparators] = useState(
        { value: '_', label: ' Underscore (_____)' });

    const [radioData, setRadioData] = useState([
        { value: '', label: '' }
    ]);
    const [progress, setProgress] = useState(0);
    const [occFlag, setOccFlag] = useState(false);


    const [message, setMessage] = useState('');

    const [selectedFilesZ, setselectedFilesZ] = useState([])

    const { radios, musiques } = useSelector(state => state.radios);



    console.log("all musiques", musiques);
    const dispatch = useDispatch()

    const { user } = useStateContext();

    const [changeModal, setChangeModal] = useState(true)
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const cleanUp = () => {

        tog_center()





    }


    useEffect(() => {
        dispatch(fetchMusiqueRequest());

        // // Check if user is defined before accessing its properties
        // const userId = user?.id;

        // // If user is undefined, handle the case gracefully
        // if (userId === undefined) {
        //     // Handle the case where user is undefined (e.g., show a message or handle the error)
        //     return;
        // }

        axiosClient.get(`musiques/`)
            .then(response => {
                dispatch(fetchMusiqueSuccess(response.data));
                // setTotalPages(Math.ceil(response.data.count / 10));
            })
            .catch(error => {
                dispatch(fetchMusiqueFailure(error.message));
            });
    }, [dispatch]);

    function tog_center() {
        setmodal_center(!modal_center)
        removeBodyCss()

    }
    const handleRadioChange = (selectedOptions) => {
        console.log("optionssssssssssssssssss: ", selectedOptions);
        setSelectedOptions(selectedOptions);
    };


    useEffect(() => {
        dispatch(fetchRadioRequest());

        const fetchAllUsers = async () => {
            let nextPage = 'radios/'; // Start with the initial endpoint
            let allRadios = [];

            try {
                while (nextPage) {
                    const response = await axiosClient.get(nextPage);
                    allRadios = [...allRadios, ...response.data.results];
                    console.log("radiossssss: ", response.data.results);
                    nextPage = response.data.next; // Update to the next page URL or null if no more pages
                }

                dispatch(fetchRadioSuccess(allRadios));
            } catch (error) {
                dispatch(fetchRadioFailure(error.message));
            }
        };
        const getDataRadios = async () => {
            if (user.id) {

                const user_id = user && user?.id

                await axiosClient.get(`targeted_radios/?user=${user_id}`).then(async (res) => {
                    const transformedData = res.data?.map(item => ({ value: item.id, label: item.name }))
                    setRadioData(transformedData)
                })
                if (radios && Array.isArray(radios)) {
                    console.table(radios);
                }
            }

        }

        getDataRadios()


        fetchAllUsers();

    }, [dispatch, user.id]);


    // console.log("All musics", musiques);

    // const handleAcceptedFilesZ = (files) => {
    //     const updatedFiles = files.map(file => Object.assign({}, file, {
    //         preview: URL.createObjectURL(file),
    //         formattedSize: formatBytes(file.size), // Define formatBytes function if needed
    //         progress: 0, // Initialize progress to 0
    //     }));

    //     setselectedFilesZ(updatedFiles);

    //     // Simulating file upload progress
    //     updatedFiles.forEach((file, index) => {
    //         simulateUploadProgress(index);
    //     });
    // };

    function handleAcceptedFilesZ(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                // progress: 0, // Initialize progress to 0

            })
        )
        setselectedFilesZ(files)
    }
    const simulateUploadProgress = (index) => {
        let progress = 0;
        const interval = setInterval(() => {
            progress += Math.random() * 10;
            if (progress > 100) {
                progress = 100;
                clearInterval(interval);
            }
            updateFileProgress(index, progress);
        }, 500);
    };
    // Function to update file progress
    const updateFileProgress = (index, value) => {
        setselectedFilesZ((prevFiles) =>
            prevFiles.map((file, idx) => (idx === index ? { ...file, progress: value } : file))
        );
    };
    const [musicDetails, setMusicDetails] = useState([]);

    useEffect(() => {
        const fetchMusicDetails = async () => {
            const details = [];

            for (const file of selectedFilesZ) {
                if (!file.name) {
                    console.error('File name is undefined or empty:', file);
                    continue; // Skip processing this file
                }

                const audio = new Audio();
                const fileName = file.name;
                const parts = fileName.split(sSeparators && sSeparators.value);
                console.log("sSeparators.valuesSeparators.value", parts.length && parts);

                if (parts.length === 2) {
                    const titre = parts[0];
                    const artist = parts[1].replace('.mp3', '');
                    const selectedRadios = selectedOptions.map(radio => radio.value).join(',');

                    audio.addEventListener('loadedmetadata', () => {
                        const durationInSeconds = Math.round(audio.duration);
                        const musicDetail = {
                            titre: titre || "",
                            artist: artist || "",
                            duration: durationInSeconds,
                            file: file,
                            radios: selectedRadios
                        };
                        details.push(musicDetail);
                        if (details.length === selectedFilesZ.length) {
                            setMusicDetails([...details]); // Ensure state update with spread operator
                        }
                    });

                    audio.addEventListener('error', (err) => {
                        console.error('Error loading audio:', err);
                        const musicDetail = {
                            titre: titre,
                            artist: artist,
                            duration: 0, // Default duration for error case
                            file: file
                        };
                        details.push(musicDetail);
                        if (details.length === selectedFilesZ.length) {
                            setMusicDetails([...details]); // Ensure state update with spread operator
                        }
                    });

                    audio.src = URL.createObjectURL(file);
                    audio.load();
                } else {
                    console.error('Invalid file name format:', fileName);
                    // Handle or log the error for invalid file names
                    notifyError(`Un de vos fichiers ne respecte pas le format avec le separateur selectionné: ${sSeparators.value}  ))))))>fichier:  ${fileName}`);
                }
            }
        };

        if (selectedFilesZ.length > 0) {
            fetchMusicDetails();
        }
    }, [selectedFilesZ, selectedOptions]);

    console.log("entities", musicDetails);


    const checkMusicAvailability = async (titre, artiste, radios) => {
        try {
            const response = await axiosClient.get(`/check__musicArtiste_r__availability/?titre=${titre}&artiste=${artiste}&radios=${radios}`);
            const data = await response.data;
            console.log("Availability data:", data);

            const results = {};
            const radioIds = radios.split(',');

            // Initialize results for all specified radios
            radioIds.forEach(radioId => {
                results[radioId] = { exists: false, id: null }; // Default to not existing and id as null
            });

            // Check each radio in the response data
            data.forEach(item => {
                const radioIdStr = item.radio_id.toString();
                if (radioIds.includes(radioIdStr)) {
                    results[radioIdStr] = { exists: item.exists, id: item.id };
                }
            });

            console.log("Results:", results);
            return results; // Return object with existence status and id for each radio
        } catch (error) {
            console.error("Error checking music availability:", error);
            return {}; // Default to empty object in case of error
        }
    };
    const notifySuccess = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });



    const notifyError = (text) => toast.error(text, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });



    const [totalMusicCount, setTotalMusicCount] = useState(0);
    const [successfullyImported, setSuccessfullyImported] = useState(0);

    const handleProgress = (event) => {
        const { loaded, total } = event;
        const percentage = Math.round((loaded * 100) / total);
        setProgress(percentage);
    };

    const [fileProgress, setFileProgress] = useState(Array(selectedFilesZ.length).fill(0));

    const handleSubmit = async () => {
        setMusicLoad(true);

        setProgress(0); // Reset progress at the beginning


        if (selectedFilesZ.length === 0 || selectedOptions.length === 0) {
            // setMessage("Veuillez remplir tous les champs!");

            notifyError("Music and Radios are required !!");

            setMusicLoad(false);
            return;
        }

        try {

            let exportedCount = 0;


            // Verify each music detail before proceeding
            for (const musicDetailsItem of musicDetails) {
                const title = musicDetailsItem.titre;
                const artist = musicDetailsItem.artist;
                const selectedRadios = selectedOptions.map(radio => radio.value).join(',');

                const availabilityData = await checkMusicAvailability(title, artist, selectedRadios);

                console.log("Availability data:", availabilityData);

                if (availabilityData) {
                    // Assuming availabilityData is an object with radioId as keys
                    for (const radioId in availabilityData) {
                        if (availabilityData[radioId].exists) {
                            // notifyError(`Music '${title}' by '${artist}' already exists in radio ${radioId}`);
                        } else {
                            // Handle the case where the music does not exist in the specified radio
                            console.log("Radio does not exist:", radioId);

                            let musicId = availabilityData[radioId].id;

                            // If musicId is null, it's a new music entry, so we should POST instead of PUT
                            if (musicId === null) {
                                const formData = new FormData();



                                const lyricsCheckUrl = `lyricsChecking/?titre=${musicDetailsItem.titre}&artiste=${musicDetailsItem.artist}`;

                                const lyricsResponse = await axiosClient.get(lyricsCheckUrl);
                                const hasLyrics = lyricsResponse.data.hasLyrics;

                                const lyrics = lyricsResponse.data;


                                console.log("hasLyricshasLyricshasLyrics", lyrics);

                                // Append hasLyrics field to the form data
                                formData.append('hasLyrics', hasLyrics);
                                formData.append('paroles', JSON.stringify(lyrics));

                                // Validate and log music details
                                formData.append('titre', musicDetailsItem.titre);
                                formData.append('artiste', musicDetailsItem.artist);
                                formData.append('duree', musicDetailsItem.duration);
                                // formData.append('artist', musicDetailsItem.artist.trim());
                                // 
                                // Append the file as a Blob object
                                const musicFile = musicDetailsItem.file;
                                if (musicFile) formData.append('musique', musicFile);

                                // Convert radio to integer
                                const newRadioPk = parseInt(radioId, 10); // New radio from availability data
                                if (!isNaN(newRadioPk)) {
                                    formData.append('radio', newRadioPk);
                                } else {
                                    console.error('Invalid radio primary key value:', radioId);
                                }

                                console.log(`Creating new music entry for radio ${newRadioPk}`);

                                try {
                                    const response = await axiosClient.post('musiques/', formData



                                    );
                                    console.log('New music entry created:', response.data);
                                    // alert("Created successfully!!");



                                } catch (error) {
                                    console.error(`Error creating new music entry for '${title}':`, error);
                                    if (error.response) {
                                        console.error('Response data:', error.response.data);
                                        console.error('Response status:', error.response.status);
                                        console.error('Response headers:', error.response.headers);
                                    } else if (error.request) {
                                        console.error('Request data:', error.request);
                                    } else {
                                        console.error('Error message:', error.message);
                                    }
                                }
                            } else {
                                // Fetch existing radios for the music item
                                try {
                                    const musicDetailResponse = await axiosClient.get(`musique_detail_view/?id=${musicId}`);
                                    let existingRadios = musicDetailResponse.data.radio;

                                    // Ensure existingRadios is an array of integers
                                    if (typeof existingRadios === 'string') {
                                        existingRadios = existingRadios.split(',').map(radio => parseInt(radio, 10));
                                    } else if (!Array.isArray(existingRadios)) {
                                        existingRadios = [];
                                    }

                                    console.log("Existing radios:", existingRadios);

                                    const formData = new FormData();


                                    const lyricsCheckUrl = `lyricsChecking/?titre=${musicDetailsItem.titre}&artiste=${musicDetailsItem.artist}`;

                                    const lyricsResponse = await axiosClient.get(lyricsCheckUrl);
                                    const hasLyrics = lyricsResponse.data.hasLyrics;

                                    const lyrics = lyricsResponse.data;


                                    console.log("hasLyricshasLyricshasLyrics", lyrics);

                                    // Append hasLyrics field to the form data
                                    formData.append('hasLyrics', hasLyrics);
                                    formData.append('paroles', JSON.stringify(lyrics));

                                    // Validate and log music details
                                    formData.append('titre', musicDetailsItem.titre);
                                    formData.append('artiste', musicDetailsItem.artist);
                                    formData.append('duree', musicDetailsItem.duration);

                                    // Append the file as a Blob object
                                    const musicFile = musicDetailsItem.file;
                                    if (musicFile) formData.append('musique', musicFile);

                                    // Combine existing radios with the new radio
                                    const newRadioPk = parseInt(radioId, 10); // New radio from availability data
                                    const allRadios = [...existingRadios];

                                    // Add new radio if it's not already in the list
                                    if (!allRadios.includes(newRadioPk)) {
                                        allRadios.push(newRadioPk);
                                    }

                                    // Append updated radios list as individual entries
                                    allRadios.forEach(radioPk => formData.append('radio', radioPk));

                                    console.log("Updated radios list:", allRadios);

                                    console.log(`Updating music with id ${musicId} for radios ${allRadios.join(',')}`);

                                    try {
                                        const response = await axiosClient.put(`musiques/${musicId}/`, formData



                                        );
                                        console.log('Music detail updated:', response.data);


                                    } catch (error) {
                                        console.error(`Error updating music detail for '${title}':`, error);
                                        if (error.response) {
                                            console.error('Response data:', error.response.data);
                                            console.error('Response status:', error.response.status);
                                            console.error('Response headers:', error.response.headers);
                                        } else if (error.request) {
                                            console.error('Request data:', error.request);
                                        } else {
                                            console.error('Error message:', error.message);
                                        }
                                    }
                                } catch (error) {
                                    console.error(`Error fetching music details for id ${musicId}:`, error);
                                }
                            }






                            // alert("finished ")





                        }
                    }
                } else {
                    console.error('Invalid response from server:', availabilityData);
                    notifyError("Invalid response from server");
                }



                exportedCount++;
                const progressPercent = Math.round((exportedCount / musicDetails.length) * 100);
                setProgress(progressPercent); // Update progress bar

                if (progressPercent >= 100) {
                    notifySuccess("Importation terminé.");
                }





            }
        } catch (error) {
            console.error("Error processing music details:", error);
            notifyError("Une erreur s'est produite lors du traitement des détails de la musique.");
        } finally {
            setMusicLoad(false);

            setProgress(0)



            setOccFlag(true)

        }
    };





    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }





    console.log('the Selected Radios are the followings : ', selectedOptions);
    console.log("the imported  musics are  teh follwoings  ", selectedFilesZ);



    useEffect(() => {


        if (!musicLoad) {
            setSelectedOptions([])

            setselectedFilesZ([])
        }


    }, [!musicLoad])

    const dropZoneS = {

        border: '4px dashed',
        borderColor: 'black transparent transparent black',
        borderStyle: 'solid none none solid',
        borderWidth: '4px 0 0 4px',
        // padding: '10px'
    };

    const defaultVisualizer = [
        { name: 'artiste1_titre1.mp3' },
        { name: 'artiste2_titre2.mp3' },
        { name: 'artiste3_titre3.mp3' },
        { name: 'artiste4_titre4.mp3' },
        { name: 'artiste5_titre5.mp3' },
        { name: 'artiste6_titre6.mp3' },

        { name: 'artiste7_titre7.mp3' }

    ]; // Example array of default visualizers


    const defaultVisualizer_ = [
        { name: 'artiste1-titre1.mp3' },
        { name: 'artiste2-titre2.mp3' },
        { name: 'artiste3-titre3.mp3' },
        { name: 'artiste4-titre4.mp3' },
        { name: 'artiste5-titre5.mp3' },
        { name: 'artiste6-titre6.mp3' },

        { name: 'artiste7-titre7.mp3' }

    ]; // Example array of default visualizers


    const [progressValue, setProgressValue] = useState(0);




    useEffect(() => {
        const increment = 0.5; // Increment value per step (adjust as needed)
        const totalTime = 10000; // Total time in milliseconds to reach 100% (adjust as needed)
        const steps = totalTime / increment; // Number of steps

        let currentProgress = 0;
        let timer;

        function updateProgress() {
            currentProgress += increment;
            const progress = Math.min(currentProgress, 100); // Ensure progress doesn't exceed 100
            setProgressValue(progress);

            if (currentProgress < 100) {
                timer = setTimeout(updateProgress, increment); // Schedule next step
            }
        }

        timer = setTimeout(updateProgress, increment); // Start the initial step

        return () => {
            clearTimeout(timer); // Clean up the timeout on unmount
        };
    }, []);



    console.log("selectedFilesZselectedFilesZ", selectedFilesZ);


    // Define the options for separators
    const separatorOptions = [
        { value: '_', label: ' Underscore (_____)' },
        { value: '-', label: 'Trait d union (--)' }
    ];


    // Handle the change event
    const handleSeparatorsChange = (selectedOptions) => {
        console.log("optiossssssssssssssss: ", selectedOptions)
        setSSeparators(selectedOptions || []); // Update state with selected options or empty array if none selected
    };



    console.log(" the fuxkign sepearator", sSeparators.value);

    return (


        <>

            <button
                style={{ marginRight: '10px' }}
                type="button"

                onClick={() => {
                    tog_center();
                    setChangeModal(true)
                }}
                className="btn btn-primary waves-effect waves-light"
                data-toggle="modal"
                data-target="#myModal"
            >
                <i style={{ fontSize: '17px', marginRight: '5px' }} className="mdi mdi-file" />
                Importer des musiques
            </button>

            <Modal
                size='lg'
                isOpen={modal_center}

                toggle={() => {
                    cleanUp()
                }}
                centered={true}
            >
                {/* {nameMessageError &&
                    <p style={{ color: 'red' }}> {nameMessageError}</p>
                } */}

                <ModalHeader style={{ fontSize: "20px" }} toggle={() => {
                    cleanUp()
                    // setSelectedImage()
                    // setSelectedImg()
                    // setImage()
                }}>
                    <h3>Importer des musiques</h3>


                </ModalHeader>

                <ModalBody className='p-4 mx-2'>


                    <form encType="multipart/form-data">
                        <Row>
                            <Col style={{ alignContent: "center" }} lg="12">

                                {/* {message &&
                                    alert(message)
                                } */}
                                <Row className="mb-3">
                                    {/* <label htmlFor="userType" className="col-md-3 col-form-label">Musiques </label> */}





                                    <Row className="mb-3  mt-4" >


                                        <Col lg="6">
                                            <Form>
                                                <Dropzone
                                                    onDrop={acceptedFiles => {
                                                        handleAcceptedFilesZ(acceptedFiles)
                                                    }}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <form encType="multipart/form-data">

                                                            <div style={{ border: '3px dashed #2573EB' }} className="dropzone dz-clickable">
                                                                <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input type="file"  {...getInputProps()} />
                                                                    <div >
                                                                        <i style={{ color: "#2573EB", fontSize: '47px', marginRight: "5px" }}





                                                                            className="mdi mdi-cloud-upload-outline" />

                                                                    </div>
                                                                    <div className="mb-3" style={{
                                                                        fontSize: "18px",
                                                                        color: "white",
                                                                        borderRadius: "10px",
                                                                        backgroundColor: "#2573EB",
                                                                        width: "130px",    // Set your desired width
                                                                        margin: "0 auto",  // Center the div horizontally
                                                                        textAlign: "center", // Optional: Center the text inside the div  

                                                                    }}>
                                                                        Browse
                                                                    </div>
                                                                    <h4>Déposer des fichiers ou parcourir</h4>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Dropzone>




                                            </Form>

                                        </Col>

                                        <Col lg="6" style={{ marginTop: "-38px" }}>
                                            <Row className="mt-3">
                                                <Col className="text-right">
                                                    <p style={{ fontWeight: 'bold' }}>Uploaded files</p>
                                                </Col>
                                                {selectedFilesZ.length > 0 && <Col className="text-left" style={{ left: 110 }}>
                                                    <p style={{ fontWeight: 'bold' }}>{selectedFilesZ.length} files</p>
                                                </Col>}
                                            </Row>

                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                {selectedFilesZ.length > 0 ? (
                                                    <div style={{ maxHeight: '290px', overflowY: 'auto' }}>
                                                        {selectedFilesZ.map((f, i) => (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                            {/* Music icon */}
                                                                            <FaMusic style={{ fontSize: '22px', color: '#2573EB' }} />
                                                                        </Col>
                                                                        <Col>
                                                                            <Link to="#" className="text-muted font-weight-bold">
                                                                                {f.path}
                                                                            </Link>
                                                                        </Col>
                                                                        <Col className="col-auto">
                                                                            {/* Example with buttons */}
                                                                            {/* <Button onClick={() => handleEyeButtonClick(f.preview)} className="btn btn-info btn-sm ml-8">
            <FaEye />
        </Button>
        <Button onClick={() => handleDeleteImagesSlideShow(i)} className="btn btn-danger btn-sm">
            <FaTrash />
        </Button> */}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                {/* <div >
                                                                    <LinearProgress
                                                                        variant="determinate"
                                                                        value={f.progress}
                                                                        style={{ marginRight: '10px' }}
                                                                    />
                                                                    <Typography
                                                                        variant="caption"
                                                                        component="div"
                                                                        color="textSecondary"
                                                                        style={{ position: 'absolute', top: '50%', left: '80%', transform: 'translate(-50%, -50%)' }}
                                                                    >
                                                                        {`${Math.round(f.progress)}%`}
                                                                    </Typography>
                                                                </div> */}
                                                            </Card>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    // Render something else or leave empty when no files are selected
                                                    <div style={{ maxHeight: '290px', overflowY: 'auto' }}>


                                                        {



                                                            sSeparators.value === "_"
                                                                ?
                                                                (defaultVisualizer.map((f, i) => (
                                                                    <Card
                                                                        className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={i + "-file"}
                                                                    >
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">
                                                                                <Col className="col-auto">
                                                                                    {/* Music icon */}
                                                                                    <FaMusic style={{ fontSize: '22px', color: '#2573EB' }} />
                                                                                </Col>
                                                                                <Col>
                                                                                    <Link to="#" className="text-muted font-weight-bold">
                                                                                        {f.name}
                                                                                    </Link>
                                                                                </Col>
                                                                                <Col className="col-auto">
                                                                                    {/* Example with buttons */}
                                                                                    {/* <Button onClick={() => handleEyeButtonClick(f.preview)} className="btn btn-info btn-sm ml-8">
            <FaEye />
        </Button>
        <Button onClick={() => handleDeleteImagesSlideShow(i)} className="btn btn-danger btn-sm">
            <FaTrash />
        </Button> */}
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Card>
                                                                ))) :
                                                                (
                                                                    defaultVisualizer_.map((f, i) => (
                                                                        <Card
                                                                            className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                                                                            key={i + "-file"}
                                                                        >
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        {/* Music icon */}
                                                                                        <FaMusic style={{ fontSize: '22px', color: '#2573EB' }} />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Link to="#" className="text-muted font-weight-bold">
                                                                                            {f.name}
                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col className="col-auto">
                                                                                        {/* Example with buttons */}
                                                                                        {/* <Button onClick={() => handleEyeButtonClick(f.preview)} className="btn btn-info btn-sm ml-8">
            <FaEye />
        </Button>
        <Button onClick={() => handleDeleteImagesSlideShow(i)} className="btn btn-danger btn-sm">
            <FaTrash />
        </Button> */}
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    ))
                                                                )


                                                        }

                                                    </div>
                                                )}
                                                {/* Render send button if files are selected */}
                                                {/* {selectedFilesZ.length > 0 && !imagesAdded && (
        <Row className="justify-content-center">
            <Col xs="auto">
                <Button style={{ marginTop: "10px", width: "100px" }} onClick={handleSendSlideImages} className="btn btn-success btn-sm">
                    <FaCheck /> Confirmer
                </Button>
            </Col>
        </Row>
    )} */}
                                            </div>
                                        </Col>
                                    </Row>


                                    <Row className="mb-3  mt-4" >

                                        {/* <label htmlFor="radio" className="col-md-3 col-form-label">Radio </label> */}

                                        <div className="col-md-12 ">

                                            <Select
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        border: state.isFocused ? '2px solid blue' : '2px solid #2573EB',
                                                    }),
                                                }}
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                value={sSeparators}
                                                onChange={handleSeparatorsChange}
                                                isMulti={false}
                                                options={separatorOptions} // Use the defined options
                                                placeholder="Sélectionnez un séparateur"
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3  mt-4" >

                                        {/* <label htmlFor="radio" className="col-md-3 col-form-label">Radio </label> */}

                                        <div className="col-md-12 ">

                                            <Select


                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        border: state.isFocused ? '2px solid blue' : ' 2px solid #2573EB',
                                                    }),
                                                }}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                value={selectedOptions}
                                                onChange={handleRadioChange}
                                                isMulti
                                                options={radioData}
                                                placeholder="Selectionnez radios"
                                            />
                                        </div>
                                    </Row>
                                </Row>

                            </Col>
                        </Row>

                    </form>
                </ModalBody>



                <ModalFooter>
                    {/* <button
                        disabled={musicLoad}

                        type="button"
                        onClick={() => {
                            handleSubmit();
                        }}
                        className="btn btn-primary waves-effect waves-light"
                        data-toggle="modal"
                        data-target="#myModal"
                    >
                        <i style={{ fontSize: '17px', marginRight: "5px" }} className="mdi mdi-music" />
                        {!musicLoad ? (
                            <span>

U
                                Importer des musiques
                            </span>
                        ) : (
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}


                    </button> */}



                    {!musicLoad ?
                        (

                            <button
                                style={{ marginRight: '10px' }}
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary waves-effect waves-light"
                                data-toggle="modal"
                                data-target="#myModal"
                                disabled={musicLoad}
                            >
                                <i style={{ fontSize: '17px', marginRight: '5px' }} className="mdi  mdi-file" />
                                Sauvegarder
                            </button>
                        ) :
                        (



                            <button
                                style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary waves-effect waves-light"
                                data-toggle="modal"
                                data-target="#myModal"
                                disabled={musicLoad}
                            >
                                <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={progress}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        color="textSecondary"
                                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                    >
                                        {`${progress}%`}
                                    </Typography>
                                </div>
                                <span style={{ marginLeft: '5px', fontSize: "10px" }}>Importing ...</span>
                            </button>


                        )}


                </ModalFooter>
            </Modal>

        </>

    )
}


