import React from 'react'

function NextSlideTransition({ disque, nextTitle, logoRadio, defaultSleeve }) {
    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: '12%'
        }}>
            <div>
                <h1 style={{ color: 'white', textTransform: 'uppercase', fontSize: 65, fontFamily: 'arial black' }}>préparez-vous on va chanter</h1>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                gap: 50
            }}>
                <div>
                    <img
                        alt=''
                        className='animation-disk' src={disque} height={250} width={250} />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    gap: 18,
                    width: '30%',
                }}>
                    <h1 style={{ color: 'white', textTransform: 'uppercase', fontSize: 35, lineHeight: 1.3, fontFamily: 'arial black' }}>
                        {nextTitle?.titre?.length > 25 ? `${nextTitle?.titre.substring(0, 25)}...` : nextTitle?.titre}
                        {/* je ne suis pas un héros */}
                    </h1>
                    <h2 style={{ color: 'white', textTransform: 'uppercase', fontFamily: 'arial black', fontSize: 30, lineHeight: 1.3 }}>
                        {nextTitle?.artist?.length > 20 ? `${nextTitle?.artist.substring(0, 20)}...` : nextTitle?.artist}
                        {/* daniel balavoine */}
                    </h2>
                </div>
                <div>
                    <img
                        alt=''
                        className='animation-disk' src={nextTitle?.album_cover ? nextTitle?.album_cover : (defaultSleeve !== "" ? defaultSleeve : logoRadio)} height={220} width={220} style={{ borderRadius: '50%' }} />
                </div>
            </div>
            {/* <div>
                <h1 style={{ color: 'white', fontSize: 60, fontWeight: 'bold', fontFamily: 'arial black' }}>Tout de suite après la pub</h1>
            </div> */}
        </div>
    )
}

export default NextSlideTransition