// radioActions.js
export const FETCH_RADIO_REQUEST = 'FETCH_RADIO_REQUEST';
export const FETCH_RADIO_SUCCESS = 'FETCH_RADIO_SUCCESS';
export const FETCH_RADIO_FAILURE = 'FETCH_RADIO_FAILURE';

export const ADD_RADIO_REQUEST = 'ADD_RADIO_REQUEST';
export const ADD_RADIO_SUCCESS = 'ADD_RADIO_SUCCESS';
export const ADD_RADIO_FAILURE = 'ADD_RADIO_FAILURE';

// Actions for slidesRadio
export const FETCH_SLIDES_RADIO_REQUEST = 'FETCH_SLIDES_RADIO_REQUEST';
export const FETCH_SLIDES_RADIO_SUCCESS = 'FETCH_SLIDES_RADIO_SUCCESS';
export const FETCH_SLIDES_RADIO_FAILURE = 'FETCH_SLIDES_RADIO_FAILURE';

// Actions for radio configurations
export const FETCH_RADIO_CONFIG_REQUEST = 'FETCH_RADIO_CONFIG_REQUEST';
export const FETCH_RADIO_CONFIG_SUCCESS = 'FETCH_RADIO_CONFIG_SUCCESS';
export const FETCH_RADIO_CONFIG_FAILURE = 'FETCH_RADIO_CONFIG_FAILURE';

export const fetchRadioRequest = () => ({
    type: FETCH_RADIO_REQUEST
});

export const fetchRadioSuccess = (radios) => ({
    type: FETCH_RADIO_SUCCESS,
    payload: radios
});

export const fetchRadioFailure = (error) => ({
    type: FETCH_RADIO_FAILURE,
    payload: error
});

export const addRadioRequest = (radioData) => ({
    type: ADD_RADIO_REQUEST,
    payload: radioData
});

export const addRadioSuccess = () => ({
    type: ADD_RADIO_SUCCESS
});

export const addRadioFailure = (error) => ({
    type: ADD_RADIO_FAILURE,
    payload: error
});

// Actions for slidesRadio
export const fetchSlidesRadioRequest = () => ({
    type: FETCH_SLIDES_RADIO_REQUEST
});

export const fetchSlidesRadioSuccess = (slidesRadios) => ({
    type: FETCH_SLIDES_RADIO_SUCCESS,
    payload: slidesRadios
});

export const fetchSlidesRadioFailure = (error) => ({
    type: FETCH_SLIDES_RADIO_FAILURE,
    payload: error
});

// Actions for radio configurations
export const fetchRadioConfigRequest = () => ({
    type: FETCH_RADIO_CONFIG_REQUEST
});

export const fetchRadioConfigSuccess = (configurations) => ({
    type: FETCH_RADIO_CONFIG_SUCCESS,
    payload: configurations
});

export const fetchRadioConfigFailure = (error) => ({
    type: FETCH_RADIO_CONFIG_FAILURE,
    payload: error
});
