import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import CookieConsentBanner from './CookiesConsent';

const VideoPlayer = () => {
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        // Check if user has already given consent
        const userConsent = Cookies.get('userConsent');
        if (userConsent) {
            setConsentGiven(true);
        }
    }, []);

    const handleConsent = () => {
        setConsentGiven(true);
    };

    useEffect(() => {
        if (consentGiven) {
            const script = document.createElement('script');
            script.src = "https://geo.dailymotion.com/player/xt5cu.js";
            script.setAttribute('data-video', 'x8yse8i');
            script.async = true;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [consentGiven]);

    return (
        <div >
            {!consentGiven && <CookieConsentBanner onAccept={handleConsent} />}
            {consentGiven && (
                <div itemscope itemtype="https://schema.org/VideoObject">
                    <meta itemprop="name" content="Melody en Chantant" />
                    <meta itemprop="description" content="Melody en Chantant" />
                    <meta itemprop="uploadDate" content="2024-05-20T16:37:54.000Z" />
                    <meta itemprop="thumbnailUrl" content="https://s1.dmcdn.net/l/WKBaI1cbIt5NzECLp/x240" />
                    <meta itemprop="embedUrl" content="https://geo.dailymotion.com/player/xt5cu.html?video=x8yse8i" />
                    <div id="dailymotion-player"></div>
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
