import React from "react"
import PropTypes from "prop-types"
import { Navigate, useLocation } from "react-router-dom"
import { useStateContext } from "../../../context/ContextProvider";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {

  // const token = localStorage.getItem("ACCESS_TOKEN")
  const isAuthenticated = localStorage.getItem("U_T");
  const { user } = useStateContext();
  const currentUserRole = user.role; // Example user role, replace it with the actual user's role
  const location = useLocation()

  // const isProtectedRoute = isAuthProtected === true;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  console.log("lpppp", location.pathname);


  if (location.pathname === "/users" && currentUserRole !== "Manager") {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  layout: PropTypes.any,
}

export default Authmiddleware
