import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Card, CardBody, Image, Alert } from "react-bootstrap";
import { useStateContext } from "../../../context/ContextProvider";
import axiosClient, { linkImage } from "../../../axios-client";
import { Media } from "reactstrap";
import { FaPencilAlt } from "react-icons/fa";
import Resizer from "react-image-file-resizer";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const UserProfile = () => {

    const { user } = useStateContext()
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [tel, setTel] = useState("");
    const [genre, setGenre] = useState("");
    const [email, setEmail] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [validated, setValidated] = useState(false);

    const [selectedImage, setSelectedImage] = useState()
    const [selectedImg, setSelectedImg] = useState()
    const [image, setImage] = useState()

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');
    const [message, setMessage] = useState('');


    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });
    };
    const handleChangeImage = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedImg(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedImage(imageFile);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (selectedImg) {
            setImage(URL.createObjectURL(selectedImg))
        }
        else {
            setSelectedImg()
        }

    }, [selectedImage, user]);
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        if (firstName) { formData.append("first_name", firstName); }
        if (lastName) { formData.append("last_name", lastName); }
        if (email) { formData.append("email", email); }
        if (genre) { formData.append("gender", genre); }

        if (country) { formData.append("pays", country); }
        if (city) { formData.append("ville", city); }
        if (street) { formData.append("rue", street); }
        if (tel) { formData.append("tel", tel); }
        if (zipCode) { formData.append("code_postal", zipCode); }
        if (selectedImage) formData.append("image", selectedImage);



        try {
            const response = await axiosClient.put(`/update_user/${user.id}/`, formData)
            if (response.data.message) {
                toast.success('votre profil est modifié avec succès', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                window.scrollTo(0, 0)
            }
        } catch (err) {
            console.log(err);
        }

    };

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(newPassword)) {
            missingRequirements.push("caractère spécial");
        } else if (!lowercaseRegex.test(newPassword)) {
            missingRequirements.push("minuscule");
        } else if (!uppercaseRegex.test(newPassword)) {
            missingRequirements.push("majuscule");
        } else if (!digitRegex.test(newPassword)) {
            missingRequirements.push("chiffres");
        } else if (!minLengthRegex.test(newPassword)) {
            missingRequirements.push("longueur minimale de 8 caractères");
        }

        if (missingRequirements.length > 0) {
            const missingText = `Vous avez besoin de ${missingRequirements.join(", ")} dans votre mot de passe.`;
            setPasswordStrength(missingText);
            setPasswordColor('#ff7070');
        } else {
            setPasswordStrength('Le mot de passe est correct!');
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [newPassword]);


    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push('Le mot de passe doit comporter au moins 8 caractères.');
        }

        if (!digitRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins un chiffre.');
        }

        if (!lowercaseRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins une lettre minuscule.');
        }

        if (!uppercaseRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins une lettre majuscule.');
        }
        if (!specialchar.test(password)) {
            errors.push('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).');
        }
        if (password.length > 20) {
            errors.push('Le mot de passe ne doit pas dépasser 20 caractères.');
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!isPasswordValid(newPassword)) {
            return;
        }
        try {
            const response = await axiosClient.put(`/change_password/${user.id}/`, {
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
                useradd: user.first_name + ' ' + user.last_name
            });
            if (response.data.success) {
                toast.success('le mot de passe est modifié avec succès', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });

                setConfirmPassword('')
                setNewPassword('')
                setOldPassword('')
                window.scrollTo(0, 0)
            }
            setMessage(response.data.error);
        } catch (err) {
            console.log(err.response.data.error);
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <Row>
                <Col lg="12">


                    <Card>
                        <CardBody>
                            <Media>
                                <div className="position-relative" style={{ width: 80, height: 80, marginRight: 10 }}>
                                    <Image
                                        style={{ objectFit: "cover" }}
                                        width={80}
                                        height={80}
                                        src={image ? image : user.image ? linkImage + user.image : user.image}
                                        rounded
                                    />
                                    <label htmlFor="imageInput" className="position-absolute" style={{
                                        cursor: 'pointer',
                                        bottom: -15,
                                        right: -10,
                                        border: "2px solid white",
                                        borderRadius: "50%"
                                    }}>
                                        <input
                                            id="imageInput"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={handleChangeImage}
                                        />
                                        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#232939', borderRadius: '50%', width: 25, height: 25 }}>
                                            <FaPencilAlt size={12} color='#fff' />
                                        </div>
                                    </label>
                                </div>
                                <Media body className="align-self-center">
                                    <div className="text-muted">
                                        <h5>{user.first_name} {user.last_name}</h5>
                                        <p className="mb-1" style={{ color: '#8699ad', fontSize: "14px" }}>{user.email}</p>
                                    </div>
                                </Media>
                            </Media>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <h4 className="card-title mb-4">Modifier votre profile</h4>
            <Card>
                <CardBody className="container px-5">
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3 gap-2 gap-md-0">
                            <Form.Group as={Col} md="12" controlId="validationCustomEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Entrez votre email"
                                    defaultValue={user.email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">Entrez une adresse email valide</Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                        <Row className="mb-3 gap-2 gap-md-0">
                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Entrez votre nom"
                                    defaultValue={user.last_name}
                                    onChange={(e) => setLastName(e.target.value)}
                                />

                                <Form.Control.Feedback type="invalid">Entrez un prénom</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Prénom</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Entrez votre prénom"
                                    defaultValue={user.first_name}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">Entrez un nom</Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                        <Row className="mb-3 gap-2 gap-md-0">
                            <Form.Group as={Col} md="6" controlId="validationCustomTel">
                                <Form.Label>Numéro de telephone</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Entrez votre numéro de telephone"
                                    defaultValue={user.tel}
                                    onChange={(e) => setTel(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">Entrez un numéro</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustomGenre">
                                <Form.Label>Genre</Form.Label>
                                <div className="d-flex gap-5">
                                    <Form.Check
                                        type="radio"
                                        label="Homme"
                                        name="genre"
                                        id="homme"
                                        value="Homme"
                                        checked={genre ? genre === "Homme" : user.gender === "Homme"}
                                        onChange={() => setGenre("Homme")}
                                        required
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Femme"
                                        name="genre"
                                        id="femme"
                                        value="Femme"
                                        checked={genre ? genre === "Femme" : user.gender === "Femme"}
                                        onChange={() => setGenre("Femme")}
                                        required
                                    />
                                </div>
                                <Form.Control.Feedback type="invalid">Choisissez un genre</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <h4 className="card-title mb-4 mt-4 ">Adresse personnelle</h4>
                        <Row className="mb-3 gap-2 gap-md-0">
                            <Form.Group as={Col} md="6" controlId="validationCustomStreet">
                                <Form.Label>Rue</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Entrez la rue"
                                    defaultValue={user.rue}
                                    onChange={(e) => setStreet(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">Entrez une rue</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustomCity">
                                <Form.Label>Ville</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Entrez la ville"
                                    defaultValue={user.ville}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">Entrez une ville</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3 gap-2 gap-md-0">
                            <Form.Group as={Col} md="6" controlId="validationCustomCountry">
                                <Form.Label>Pays</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Entrez le pays"
                                    defaultValue={user.pays}
                                    onChange={(e) => setCountry(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">Entrez un pays</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustomZip">
                                <Form.Label>Code postal</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Entrez un code postal"
                                    defaultValue={user.code_postal}
                                    onChange={(e) => setZipCode(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">Entrez un code postal</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <Button type="submit">Enregistrer</Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>

            <h4 className="card-title mb-4 mt-4 ">Sécurité de compte</h4>
            <Card>
                <CardBody className="container px-5">
                    <Form onSubmit={handleFormSubmit}>

                        {/* <Row>
                        <Col className="d-flex align-items-center justify-content-center" md="4" >
                           <Image width={'150'} height={'150'} src="https://cdn-icons-png.flaticon.com/512/103/103089.png" />
                        </Col> */}
                        <div className="d-flex justify-content-center ">
                            <Col md="12">
                                {message &&
                                    <Alert variant={"danger"}>
                                        {message}
                                    </Alert>
                                }
                                <Row className="mb-3 gap-2 gap-md-0">
                                    <Form.Group as={Col} md="12" controlId="validationCustomOldPassword">
                                        <Form.Label>Ancien mot de passe</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Entrez l'ancien mot de passe"
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3 gap-2 gap-md-0">

                                    <Form.Group as={Col} md="6" controlId="validationCustomPassword">
                                        <Form.Label>Nouveau mot de passe</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Entrez le nouveau mot de passe"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                        {newPassword.length > 0 ?
                                            <p style={{ color: passwordColor, marginTop: 2 }}>{`${passwordStrength}`}</p>
                                            : ""}
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustomConfirmPassword">
                                        <Form.Label>Confirmez le mot de passe</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Confirmer votre mot de passe"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>
                        </div>

                        {/* </Row> */}




                        <div className="d-flex justify-content-end">
                            <Button type="submit">Changer le mot le passe</Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default UserProfile;
